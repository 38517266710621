import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';


const ViewLogs = ({ workflowId }) => {
	const latestWorkflow = useSelector(state => state.latestWorkflow);

	const eventCode = latestWorkflow?.latestWorkflow?.step2?.eventCode
	//console.log({eventCode})

	const flowlist = [
		{
			key: "CSIN",
			value: "https://nettverk.io/docs/nettverk/xero-integration/create-invoice/"
		},{
			key: "CCON",
			value: "https://nettverk.io/docs/nettverk/xero-integration/create-contact/"
		},
		{
			key: "CITM",
			value: "https://nettverk.io/docs/nettverk/xero-integration/create-item/",
		},
		{
			key: "CCRNT",
			value: "https://nettverk.io/docs/nettverk/xero-integration/create-credit-note/",
		},
		{
			key: "UREC",
			value: "https://nettverk.io/docs/nettverk/xero-integration/update-record/",
		}
	]

	const handleClick = () => {
		window.open(flowlist.find(value => value.key === eventCode)?.value,"_blank")
	}

	return (
		<Row justify='end'>
			<Col style={{ fontSize: '1rem' }}>
				{/* <Link to={`/system/workflows/${workflowId}/logs`}>View Logs</Link> */}
			</Col>
			<Button style={{ zIndex: "99999" }} className='float-btn' onClick={() => handleClick()} shape="round" type="primary">Need help <QuestionOutlined/></Button>
		</Row>
	);
};
export default ViewLogs;
