export const getDestinatonApps = (apps = [], orgin)=>{
    const tempArr = [];
    switch (orgin) {
        case "SFDC":
            tempArr.push(...apps.filter(value => value.code === "XERO"));
            break;
        case "WC":
        case "XERO":
            tempArr.push(...apps.filter(value => value.code === "SFDC"));
            break;    
        default:
            tempArr.push(...apps);
            break;
    }
    return tempArr;
}