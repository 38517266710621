import React, { useEffect, useState, useContext } from 'react';
import { Row, Select } from 'antd';
import { PrimaryButton } from '../../Commons';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import { fetchSalesForcePricebooks} from '../../Http/salesforce/triggerObject';
import { isSuccess } from '../../Http';

const enableOpt = [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ];

const ChooseMultiCurrencyOption = () => {
	const { mappings, actionData, setActionData} = useContext(WorkflowContext);
	const { appName, next, id } = useContext(StepContext);
	const [options, setOptions] = useState([]);
	const [option, setOption] = useState();
	const [optionId, setOptionId] = useState();
	const [preSelectedOption, setPreSelectedOption] = useState({});
	const [loading, setLoading] = useState(true);

	//2. When retrieved, fetch the old selected sobject if any
	useEffect(() => {
		if (enableOpt.length > 0) getPrepopulatedEnabledOpt();
	}, [enableOpt]);

	const getPrepopulatedEnabledOpt = async () => {
		
		mappings?.forEach((v) =>{
			if(v.type === 'CON' && v.field === 'MultiCurrencyEnabled'){
				enableOpt?.forEach((m)=>{
					if(m.value === v.value){
						setOption(m.label);
						setPreSelectedOption({ type: v.type, field: v.field, value: v.value,  stepId: id, valueStepOrder: v.valueStepOrder })
						setLoading(false);
					}
				})
			}
		})
	};

	const handleSelect = (oVal) => {
		setOptionId(oVal)
		enableOpt?.forEach((m)=>{
			if(m.value === oVal){
				setOption(m.label);
			}
		})
	};
	const handleMapping = ()=>{
		let MAPPINGS = [];
		MAPPINGS = optionId 
		? [...actionData, 
			{ type: 'LKUP', field: 'CurrencyIsoCode', value: 'currency', stepId: id, valueStepOrder: 1 },
            { type: 'CON', field: 'MultiCurrencyEnabled', value: optionId, stepId: id, valueStepOrder: 1 }]
		: [...actionData, preSelectedOption];
		console.log({MAPPINGS})
		setActionData(MAPPINGS);
		next();
	}
	console.log({option})

	return (
		<StepContainer title='Choose option'>
			<div>
				{`${appName} Multi Currency Enabled: `}
			</div>

			<Row justify='center'>
				<Select
					className='fill-width rounded'
					loading={loading}
					onSelect={handleSelect}
					placeholder='Select'
					showSearch
					value={option}
					optionFilterProp='children'>
					{enableOpt.map((v, k) => (
						<Select.Option value={v.value} key={k}>
							{v.label}
						</Select.Option>
					))}
				</Select>
			</Row>

			<Row justify='center'>
				<PrimaryButton
					className='btn-long'
					size='large'
					onClick={() => handleMapping()}>
					Continue
				</PrimaryButton>
			</Row>
		</StepContainer>
	);
};

export default ChooseMultiCurrencyOption;
