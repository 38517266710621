/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Form, Select, Card } from 'antd';
import './style.scss';
import { WorkflowContext, StepContext } from '../../Commons/Context';
import { keys as keycreate, isObject } from 'lodash';
import { PrimaryButton } from '../../Commons';
import { api } from '../../Http';
import { requiredRule, flatten } from '../../Commons/utils';
import { useHistory } from 'react-router-dom';
const SalesItemForm = () => {
    const { sampleData, sampleData2, SObject, setActionData, mappings,workflowId,additionalQuery, setMappings, setOrganisation, form } = useContext(WorkflowContext);
    const { id, next } = useContext(StepContext);

    const [keys, setKeys] = useState([]);
    const [organisations, setOrganisations] = useState([]);
    const [returnMap, setReturnMap] = useState(false);
    const [message, setMessage] = useState(undefined);
    const history = useHistory()

    const taxTypes = [
        { label: 'GST on Income (OUTPUT) ', value: 'OUTPUT' },
        { label: 'GST on Expenses (INPUT)', value: 'INPUT' },
        { label: 'GST Free Expenses (EXEMPTEXPENSES)', value: 'EXEMPTEXPENSES' },
        { label: 'GST Free Income (EXEMPTOUTPUT)', value: 'EXEMPTOUTPUT' },
        { label: 'BAS Excluded (BASEXCLUDED)', value: 'BASEXCLUDED' },
        { label: 'GST on Imports (GSTONIMPORTS)', value: 'GSTONIMPORTS' },
    ];

    const boolOptions = [
        { label: 'True', value: true },
        { label: 'False', value: false },
    ];


    const handleSkip = (maps) => {
        api.post('/api/v1/workflows/mappings', { mappings: maps, sObject: SObject.name, workflowId: workflowId, additionalQuery: additionalQuery })
            .then(resp => setMessage(resp.data.message))
            .catch(e => console.log(e));

        setTimeout(() => {
            history.push('/system/workflows');
        },1000)
    };

    useEffect(() => {
        setKeys(keycreate(sampleData));
        let tmp = {};
        mappings?.forEach((v) =>
            (v.type === 'TRIG')
                ?
                (
                    tmp[v.value] = [`${v.valueStepOrder}.${v.field}`],
                    tmp[v.label] = `${v.valueStepOrder}.${v.value}: ${sampleData[v.value]}`
                )
                :
                (v.type === 'ACT')
                    ?
                    (
                        tmp[v.field] = [`${v.valueStepOrder}.${v.value}`],
                        tmp[v.label] = `${v.valueStepOrder}.${v.field}: ${sampleData[v.value]}`
                    )
                    :
                    (tmp[v.field] = `${v.value}`)
        );
        if (tmp) {
            //console.log({tmp})
            form.setFieldsValue(tmp);
        }
    }, []);

    const handleSubmit = () => {
        form
            .validateFields()
            .then((rawFormData) => {
                let ACTION_DATA = {}; //Data to be send as a product of the trigger
                let MAPPINGS = [];

                let flatFormData = flatten({ ...rawFormData });

                keycreate(flatFormData)
                    .filter((k) => flatFormData[k])
                    .map((key) => {
                        if (Array.isArray(rawFormData[key]) && rawFormData[key].length === 0) {
                            // skip
                        } else {
                            let val = flatFormData[key].split('.');
                            let kname = val.length > 1 ? val[1] : val[0];
                            let snum = val.length > 1 ? val[0] : undefined;

                            let kval = snum && parseInt(snum) === 1 ? sampleData[kname]
                                : snum && parseInt(snum) === 2 ? sampleData2[kname]
                                    : kname;
                                

                            ACTION_DATA = { ...ACTION_DATA, [key]: kval };
                            MAPPINGS = snum ?
                                (key === 'ItemID')
                                    ? [...MAPPINGS, { type: 'ACT', field: key, value: kname, stepId: id, valueStepOrder: snum }]
                                    : [...MAPPINGS, { type: 'TRIG', field: kname, value: key, stepId: id, valueStepOrder: snum }]

                                :
                                (key === 'ItemID')
                                    ? [...MAPPINGS, { type: 'ACT', field: key, value: kname, stepId: id, valueStepOrder: snum }]
                                    : [...MAPPINGS, { type: 'CON', field: key, value: kname, stepId: id }];
                        }
                        return key
                    });
                //console.log({ ACTION_DATA, MAPPINGS });
                setActionData(ACTION_DATA);
                setMappings(MAPPINGS);
                handleSkip(MAPPINGS)
                // next();
            })
            .catch((e) => console.log(e));
    };

    const RoundedSelect = (props) => {
        const { label, placeholder, name, required, specialOptions, specialOptionsOnly, hint, onSelect } = props;

        return (
            <Col span={22}>
                <div className='label'>
                    {label} <span className={required ? 'fr' : 'hidden'}>(required)</span>
                </div>
                <Form.Item name={name} required={required} rules={required ? [requiredRule] : null}>
                    <Select className='rounded' mode={specialOptions ? null : 'tags'}
                        placeholder={placeholder} allowClear onSelect={onSelect}>

                        {specialOptions && specialOptions.map((v) => <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>)}

                        {!specialOptionsOnly &&
                            keys.map(
                                (v, k) =>
                                    !isObject(sampleData[v]) && (
                                        <Select.Option value={`1.${v}`} key={`1.${k}`}>
                                            <div className='opt-div'>
                                                <span className='key'>{`1.${v}:  `}</span>
                                                {`${sampleData[v]}`}
                                            </div>
                                        </Select.Option>
                                    )
                            )}
                    </Select>
                </Form.Item>
                {hint && <div className='hint'>{hint}</div>}
            </Col>
        );
    };

    return (
        <Row className='padded xinvform'>
            <div className='title'> {returnMap ? 'Return Mapping' : 'Customize Item'} </div>
            <Col span={24}>
                <Form layout='vertical'
                    form={form}
                    scrollToFirstError={true}
                    onFinish={handleSubmit}
                    

                >
                    <Row hidden={returnMap} justify='center'>

                        <RoundedSelect label='Name' name='Name' placeholder='Type or insert..' required/>
                        <RoundedSelect label='Description' name='Description' placeholder='Type or insert..' />
                        <RoundedSelect label='Item Code' name='Code' placeholder='Type or insert..' required />

                        <RoundedSelect label='Price - Sales Details' name='SalesDetails.UnitPrice' placeholder='Type or insert..' />
                        <RoundedSelect label='Tax Type - Sales Details' name='SalesDetails.TaxType' placeholder='Type or insert..' specialOptions={taxTypes} />
                        <RoundedSelect label='Account Code - Sales Details' name='SalesDetails.AccountCode' placeholder='Type or insert..' />
                        <RoundedSelect label='Is Sold' name='IsSold' placeholder='Choose one..' specialOptions={boolOptions} />


                        <RoundedSelect label='Price - Purchase Details' name='PurchaseDetails.UnitPrice' placeholder='Type or insert..' />
                        <RoundedSelect label='Tax Type - Purchase Details' name='PurchaseDetails.TaxType' placeholder='Type or insert..' specialOptions={taxTypes} />
                        <RoundedSelect label='Account Code - Purchase Details' name='PurchaseDetails.AccountCode' placeholder='Type or insert..' />
                        <RoundedSelect label='Is Purchased' name='IsPurchased' placeholder='Choose one..' specialOptions={boolOptions} />

                        <RoundedSelect label='Purchase Description' name='PurchaseDescription' placeholder='Type or insert..' />

                        <PrimaryButton onClick={() => setReturnMap(true)} className='btn-long' size='large' htmlType='button'>
                            Continue
                        </PrimaryButton>
                    </Row>
                    <Row hidden={!returnMap} justify='center'>
                        <Row className={message ? null : 'hidden'} justify='center'>
                            <Col span={22} className="with-prompt">
                                <Card className={'message'}>{message}</Card>
                            </Col>
                        </Row>
                        <RoundedSelect
                            label='ItemID'
                            name='ItemID'
                            placeholder='Type or insert..'
                        />
                        <PrimaryButton className='btn-long' size='large' htmlType='submit'>
                            Continue
                        </PrimaryButton>

                    </Row>
                </Form>
            </Col>
        </Row>
    );
};

export default SalesItemForm;