import React from 'react';
import { Row, Col, Input, Tooltip } from 'antd';
import { api } from '../Http';
import { useSelector } from 'react-redux';
import { getLatestWorkflow } from '../redux/slices/workflow/latestWorkflow';

const NameEditor = ({workflowData}) => {
	// const workflowData = useSelector(getLatestWorkflow);

	const onUpdateWorkflowName = (e) => {
		let newName = e.target.value;
		//console.log(newName);
		if (newName !== workflowData.workflowName) {
			api.patch(`/api/v1/workflows/${workflowData.workflowId}`, {
				name: newName,
			});
		}
	};

	return workflowData ? (
		<Row justify='center'>
			<Col span={12}>
				<Tooltip title='Click to edit workflow name.'>
					<Input
						onBlur={onUpdateWorkflowName}
						defaultValue={workflowData.workflowName}
						className='input-wf-name'
						placeholder='Edit Workflow Name'
					/>
				</Tooltip>
			</Col>
		</Row>
	) : null;
};

export default NameEditor;
