import { createSlice } from '@reduxjs/toolkit';
import { Mapping } from '../../Http/models/Mapping.model';

// mapping: {
//         field: String,
//         value: String,
//         type: String,
//         stepId: String,
// }

const mappingSlice = createSlice({
        name: 'mappings',
        initialState: {
                mappings: Mapping.List()
        },
        reducers: {
                addMappings: (state, {payload}) => {
                        state.mappings = payload;
                },
                updateMappings: (state, {payload}) => {
                        const newMappingList = payload;
                        //console.log({newMappingList});
                        newMappingList.map(newMappingEl => {
                                const index = state.mappings.findIndex(m => m.field === newMappingEl.field);
                                if(index === -1){
                                        // add new mapping to the list
                                        //console.log('adding new mapping');
                                        state.mappings[index] = newMappingEl;
                                } else {
                                        // update existing mapping
                                        state.mappings.push(newMappingEl);
                                }
                                return newMappingEl
                        });
                }
        }
});

export const getMappings = (state) => {
        return Mapping.List(state.mappings.mappings);
};

export const {addMappings, updateMappings} = mappingSlice.actions;

export default mappingSlice.reducer;