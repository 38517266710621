import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result, Button, Col } from 'antd';
import jwt_decode from 'jwt-decode';
const NotFound = () => {
	const history = useHistory();
	const token = localStorage.getItem('token');
	const decodedToken = token && jwt_decode(token);
	
	return (
		<>
		{
			token ?
			<Col span={10} offset={6}>
				<Result
					status='404'
					title='404'
					subTitle='Sorry, the page you visited does not exist.'
					extra={
						<Button
							type='primary'
							onClick={() => decodedToken?.userType === 'ADM' ? history.replace('/system/workflows-by-admin') :  history.replace('/system/workflows')}>
							Back Home
						</Button>
					}
				/>
					
			</Col>
			:
			<Result
				status='404'
				title='404'
				subTitle='Sorry, the page you visited does not exist.'
				extra={
					<Button
						type='primary'
						onClick={() => history.replace('/login')}>
						Back Home
					</Button>
				}
				/>
		}
		</>
		
		
		
		
	);
};

export default NotFound;
