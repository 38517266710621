import { createSlice } from '@reduxjs/toolkit';

const callbackSlice = createSlice({
        name: 'callback',
        initialState: {
                callback: true
        },
        reducers: { 
                toggleCallback: (state, {payload}) => {
                        state.callback = payload;
                        //console.log({stateCallback: state.callback});
                },
                disableCallback: (state) => {
                        state.callback = false;
                }
        },
});

export const { toggleCallback, disableCallback } = callbackSlice.actions;
export const getCallback = (state) => {
        return state.callback.callback;
};
export default callbackSlice.reducer;