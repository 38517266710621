import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserProfile, sendPasswordResetLink, updateUserProfile } from '../../Http/users';

export const fetchUserProfile = createAsyncThunk(
    'profile/fetchUserProfile',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getUserProfile();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateUserProfileThunk = createAsyncThunk(
    'profile/updateUserProfile',
    async (userData, { rejectWithValue }) => {
        try {
            const response = await updateUserProfile(userData);
            if (response.code === 0) {
                //return response.message;
                return userData;
            } else {
                return rejectWithValue(response.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
export const changePasswordThunk = createAsyncThunk(
    'profile/changePassword',
    async (email, { rejectWithValue }) => {
        try {
            const response = await sendPasswordResetLink(email);
            if (response.code === 0) {
                return response.message;
            } else {
                return rejectWithValue(response.message);
            }
        } catch (error) {
            return rejectWithValue('Network Error');
        }
    }
);

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        data: {},
        loading: false,
        error: null,
        changePasswordStatus: null, // To track change password status
    },
    reducers: {
        resetChangePasswordStatus: (state) => {
            state.changePasswordStatus = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchUserProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateUserProfileThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateUserProfileThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.data = action.payload; // Update Redux state with new data
            })
            .addCase(updateUserProfileThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(changePasswordThunk.pending, (state) => {
                state.changePasswordStatus = { loading: true, success: null, message: null };
            })
            .addCase(changePasswordThunk.fulfilled, (state, action) => {
                state.changePasswordStatus = { loading: false, success: true, message: action.payload };
            })
            .addCase(changePasswordThunk.rejected, (state, action) => {
                state.changePasswordStatus = { loading: false, success: false, message: action.payload };
            });
    },
});

export const { resetChangePasswordStatus } = profileSlice.actions;


export default profileSlice.reducer;
