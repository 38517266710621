import { api, FormatResponse } from '../index';
const ALL_SUBSCRIPTIONS = '/api/v1/subscriptions/active';
const ACTIVE_SUBSCRIPTION = '/api/v1/user-subscriptions/latest/';
const CANCEL_SUBSCRIPTION = '/api/v1/stripe/cancel-subscription';

export const getallsubscriptions = async () => {
    const response = await api.get(ALL_SUBSCRIPTIONS);

    return new FormatResponse(response);
};

export const getActiveSubscription = async() => {
    const response = await api.get(ACTIVE_SUBSCRIPTION)
    //console.log({response})
    return new FormatResponse(response)
}


export const cancelSubscription = async(id) => {
    const response = await api.post(CANCEL_SUBSCRIPTION,id)
    return new FormatResponse(response)
}