import { Col, Table, Row, Button, Result, Select, Input, Form, Modal, Switch  } from 'antd';
import { CaretRightOutlined, CaretLeftOutlined, DeleteFilled, WarningFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { TableWrapper } from '../../Commons';
import { useHistory } from 'react-router-dom';
import { startCase } from 'lodash';
import './style.scss';
import { deleteAUserByAdmin, fetchAllUsers, updateTfaStatus, updateUserProfile } from '../../Http/users';

const { Option } = Select;
      
const Users = (props) => {
	const [data, setData] = useState([]);
	const [refresh,setRefresh] = useState(true);
	const [page, setPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState(undefined);
	const [error, setError] = useState(false);
	const history = useHistory();
	const [form] = Form.useForm();

	useEffect(() => {
		getAllUsers();
	}, [page, refresh]);


	const getAllUsers = async (params={}) => {
		setLoading(true);
		params.page = page;
		const usersRes = await fetchAllUsers({...params});
		//console.log({usersRes})
		if(usersRes) setLoading(false);
		if(usersRes.code === 0){
			setData(usersRes.data.users);
			setError(false);
			if (!limit) setLimit(usersRes.data.limit);
		} else {
			setError(true);
		}
	};

	const extractCols = () => {
		if (!data || data.length === 0) return [];

		let jsonKeys = Object.keys(data[0]);
		let columns = [];

		jsonKeys.forEach((v) => {
			//console.log({v});
			if(v === 'sourceData' || v === 'responseData' || v === 'tfaEnabled') {
				// escape
			} else {
				let cell = {
					title: startCase(v) ,
					dataIndex: v,
					key: v,
					align: 'center',
					ellipsis: true,
				};
				columns.push(cell);
			}
		});
		const action = {
			title: 'Action',
			key: 'operation',
			fixed: 'right',
			width: 300,
			render: (record) => {
				return(
				<div style={{display:"flex",justifyContent:"space-around"}}>
					<Button
						onClick={() => confirmDelete(record?.id)}
						type='link'
						style={{ color: 'red' }}
						icon={
							<DeleteFilled style={{ fontSize: '1.5rem' }} />
							}></Button>
					<Button style={{background: '#252B6A', borderColor: '#252B6A'}} type="primary" danger onClick={()=>history.replace(`/system/workflows/user/${record.id}`)}>View Workflows</Button>
				</div>)
			},
		};
		const actionfor2fa = {
			title: '2fa',
			key: 'operation',
			align:"center",
			width: 100,
			render: (record) => {
				return(
				<div style={{display:"flex",justifyContent:"space-around"}}>
					<Switch
						id={record?.id}
						checked={record?.tfaEnabled}
						onChange={() => handleTfa(record)}
					/>
					
				</div>)
			},
		};
		columns.push(actionfor2fa);
		columns.push(action);
		return columns;
	};


	const extractRows = () => {
		if (!data || data.length === 0) return [];
		let rows = [];
		data.forEach((v, k) => {
			rows.push({ ...v, key: k });
		});
		return rows;
	};

	const CustomPagination = () => {
		let style = {
			rowStyle: { margin: '.5rem' },
			colStyle: { marginRight: '.5rem' },
		};
		return (
			<Row justify='end' style={style.rowStyle}>
				<Col style={style.colStyle}>
					<Button disabled={page < 1} onClick={() => setPage(page - 1)}>
						<CaretLeftOutlined />
					</Button>
				</Col>
				<Col>
					<Button
						disabled={data.length < limit}
						onClick={() => setPage(page + 1)}>
						<CaretRightOutlined />
					</Button>
				</Col>
			</Row>
		);
	};
	//console.log({data})

	const confirmDelete = (id) => {
		Modal.confirm({
			title: 'Delete User',
			content: <div>Are you sure you want to delete this user?</div>,
			icon: <WarningFilled />,
			onOk: async () => {
				const res = await deleteAUserByAdmin(id);
				if(res.code === 0){
					setRefresh(!refresh)
				}
			},
		});
	};

	const onFinishFilter = (value) => {
		const params = {};
		if(value.firstName) params.firstName = value.firstName;
		if(value.lastName) params.lastName = value.lastName;
		if(value.status) params.status = value.status;
		if(value.email) params.email = value.email;
		getAllUsers({...params});
	}

	const clearSearchValues = ()=>{
		form.resetFields()
		setRefresh(!refresh);

	}
	const handleTfa = async(record)=>{
		const newData = {tfaEnabled: !record?.tfaEnabled}
		const res = await updateTfaStatus(record.id, newData);
		if(res.code === 0){
			setRefresh(!refresh)
		}
	}

	return (
		
		<>
			<div style={{
				justifyContent: 'start', 
				display: 'flex', 
				width: '100%'
				}}>
				<Form
					form={form}
					name="advanced_search"
					className="ant-advanced-search-form"
					onFinish={onFinishFilter}
					style={{width: '100%'}}
				>
					<Row gutter={24}>
						<Col span={4} style={{paddingLeft: '20px'}}>FirstName : </Col>
						<Col span={5}>LastName : </Col>
						<Col span={7}>Email : </Col>
						<Col span={4}>Status : </Col>
					</Row>
					<Row gutter={24}>
						<Col span={4} style={{paddingLeft: '20px'}}>
							<Form.Item name="firstName">
								<Input placeholder='First Name' />
							</Form.Item>
						</Col>
						<Col span={5}>
							<Form.Item name="lastName" >
								<Input placeholder='Last Name' />
							</Form.Item>
						</Col>
						<Col span={7}>
							<Form.Item name="email">
							  <Input placeholder='Email' />
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item name="status" >
								<Select
									placeholder="Select status"
									allowClear
								>
									<Option value="active">Active</Option>
									<Option value="inactive">Inactive</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={4} style={{textAlign: 'right'}}>
							<Button type="primary" htmlType="submit">
								Search
							</Button>
							<Button style={{margin: '0 8px'}} onClick={() => clearSearchValues()}>
								Clear
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
			
			<TableWrapper style={{marginBottom:"70px", padding:0}} className='wrapper'>
				{!error ? (
					<>
					<Table 
						loading={loading}
						scroll={{ x: 'max-content' }}
						pagination={false}
						dataSource={extractRows()}
						columns={extractCols()}
					>

					</Table>
					<CustomPagination />
					</>
				) : (
					<Result
						status='404'
						title='404'
						subTitle='Sorry, the page you visited does not exist.'
						extra={
							<Button
								type='primary'
								onClick={() => history.replace('/system/workflows')}>
								Back Home
							</Button>
						}
					/>
				)}
			</TableWrapper>
		</>
	);
};

export default Users;
