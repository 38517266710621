/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Form, Spin } from "antd";
import { TableWrapper } from "../../Commons";
import { WorkflowContext } from "../../Commons/Context";
import BasicWorkflow from "./Basic/BasicWorkflow";
import BasicWorkflowXero from "./XERO/BasicWorkflowXero";
import CreateXeroInvoiceWorkflow from "./SFDC/CreateXeroInvoiceWorkflow";
import CreateXeroQuoteWorkflow from "./SFDC/CreateXeroQuoteWorkflow";
import ViewLogs from "../../Components/ViewLogs";
import NotFound from "../../Components/NotFound";
import NameEditor from "../../Components/NameEditor";
import { fetchWorkflowById } from "../../Http/workflow";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addLatestWorkflow,
  getLatestWorkflow,
} from "../../redux/slices/workflow/latestWorkflow";
import { WorkflowData } from "../../Http/models/WorkflowData.model";
import CreateXeroPurchaseOrderWorkflow from "./SFDC/CreateXeroPurchaseOrderWorkflow";


const WorkFlow = (props) => {
  const dispatch = useDispatch();
  const latestWorkflow = useSelector(getLatestWorkflow);
  //console.log({ latestWorkflow });
  const ev1code = latestWorkflow.step1.eventCode;
  // ! please use useParams() from react-router-dom
  // const { id } = useParams();
  const [refresh, setRefresh] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [form] = Form.useForm();
  window.onSuccess = (refresh) => setRefresh(refresh);

  //WorkflowData that contains (Step1 and Step2)
  const [workflowData, setWorkflowData] = useState(new WorkflowData());

  //The Selected Salesforce Object to Listen To
  const [SObject, setSObject] = useState(undefined);

  // The Selected Salesforce Object 2 to listed to
  const [SObject2, setSObject2] = useState(undefined);

  //The Selected Xero Organisation
  const [organisation, setOrganisation] = useState(undefined);

  //Sample Trigger Object Data that will be mapped to Action Data
  const [sampleData, setSampleData] = useState(undefined);

  //Sample Data from find object in SFDC
  const [sampleData2, setSampleData2] = useState(undefined);

  //Action Data to be sent
  const [actionData, setActionData] = useState(undefined);

  //mappings to be saved
  const [mappings, setMappings] = useState(undefined);

  const [additionalQuery, setAdditionalQuery] = useState(undefined);

  // const subs = JSON.parse(localStorage.getItem('subscription'))

  useEffect(() => {
    if (refresh) {
      const workflowId = props.match.params.id;
      getWorkflow(workflowId);
    }
  }, [refresh]);

  const getWorkflow = async (workflowId) => {
    const workflowSingleRes = await fetchWorkflowById(workflowId);
    if (workflowSingleRes.code === 0) {
      const data = workflowSingleRes.data;
      //console.log({ dispatch: data });
      dispatch(addLatestWorkflow(workflowSingleRes.data));
      setWorkflowData(data);
      setMappings(data.mappings);
    } else setNotFound(true);
    setRefresh(false);
  };

  if (refresh) {
    return(
      <div style={{textAlign:'center'}}>
        <Spin />
      </div>
    ) ;
  }

  return (
    <TableWrapper className="common-style-wokflow">
      <ViewLogs workflowId={props.match.params.id} />
      {workflowData?.workflowName ? (
        <NameEditor workflowData={workflowData} />
      ) : (
        <div></div>
      )}

      <Row justify="center">
        <Col span={24}>
          {latestWorkflow.workflowId ? (
            <WorkflowContext.Provider
              value={{
                SObject,
                setSObject,
                SObject2,
                setSObject2,
                sampleData,
                setSampleData,
                sampleData2,
                setSampleData2,
                actionData,
                setActionData,
                mappings,
                setMappings,
                additionalQuery,
                setAdditionalQuery,
                organisation,
                setOrganisation,
                workflowId: latestWorkflow.workflowId,
                form,
              }}
            >
              {latestWorkflow.step2.eventCode === "CSIN" ? (
                <CreateXeroInvoiceWorkflow workflowData={latestWorkflow} />
              ) : latestWorkflow.step2.eventCode === "CXQT" ? (
                <CreateXeroQuoteWorkflow workflowData={latestWorkflow} />
              ): latestWorkflow.step2.eventCode === "XNPO" ? (
                <CreateXeroPurchaseOrderWorkflow
                  workflowData={latestWorkflow}
                />
              ) : latestWorkflow.step2.eventCode === "XNPO" ? (
                <CreateXeroPurchaseOrderWorkflow
                  workflowData={latestWorkflow}
                />
              ) :ev1code === "XNREC" ||
                ev1code === "FITM" ||
                ev1code === "XNINV" ||
                ev1code === "XQUP" ||
                ev1code === "XIUP" 
                ? (
                <BasicWorkflowXero />
              ):
               (
                <BasicWorkflow />
              )}
            </WorkflowContext.Provider>
          ) : notFound ? (
            <NotFound />
          ) : null}
        </Col>
      </Row>
    </TableWrapper>
  );
};

export default WorkFlow;
