import React, { useState } from 'react';
import { Row, Col, Card, Steps } from 'antd';

import { StepContext } from '../../Commons/Context';

const { Step } = Steps;

const Process = ({ app, stepData, message, order }) => {
	//console.log({app, stepData, order})
	const [current, setCurrent] = useState(0);
	const { steps } = app;
	//console.log({steps})

	const next = () => {
		if (current < steps.length - 1) {
			setCurrent(current + 1);
		}
	};

	const handleJumpStep = (v) => {
		if (current >= v) {
			setCurrent(v);
		}
	};

	return (
		<Row justify='center'>
			<Col xl={16} sm={18} xs={24}>
				<Steps current={current} size='small' onChange={handleJumpStep}>
					{steps.map((v) => (
						<Step key={v.title} title={v.title} />
					))}
				</Steps>
				<Card className='card'>
					<Row>
						<Col>
							<div className='wf-message'>{message}</div>
							<div className='wf-title'>{`${order}. ${stepData.shortDesc} in ${stepData.appName}`}</div>
						</Col>
					</Row>
					<StepContext.Provider value={{ ...stepData, next }}>
						{steps[current].content()}
					</StepContext.Provider>
				</Card>
			</Col>
		</Row>
	);
};

export default Process;
