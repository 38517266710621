import React, { useEffect, useState } from 'react';
import { Col, List, Skeleton, Avatar, Card, Switch, Button, Modal } from 'antd';
// import { api } from '../../Http';
import {
	CaretRightOutlined,
	DeleteFilled,
	WarningFilled,
} from '@ant-design/icons';

import { Link } from 'react-router-dom';

import { fetchWorkFlows, deleteWorkFlowById, toggleStatus } from '../../Http/workflow';
import { useDispatch, useSelector } from 'react-redux';
import { addWorkflow, getAllWorkflow, deleteWorkflow } from '../../redux/slices/workflow/workflowList';
import { addLatestWorkflow } from '../../redux/slices/workflow/latestWorkflow';
import AddWorkflow from '../../Components/AddWorkflow';
import './style.scss'

const WorkflowList = () => {
	// const [workflows, setWorkflows] = useState([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const workflows = useSelector(getAllWorkflow);

	useEffect(() => {
		getWorkFlows();
	}, []);

	const getWorkFlows = async () => {
		setLoading(true);
		const workflowRes = await fetchWorkFlows();
		if(workflowRes) setLoading(false);
		dispatch(addWorkflow(workflowRes.data));
	};
	//console.log({workflows})

	const handleToggle = async (id) => {
		const workflowRes = await toggleStatus(id);
		dispatch(addWorkflow(workflowRes.data));
	};

	const confirmDelete = (id) => {
		Modal.confirm({
			title: 'Delete Workflow',
			content: <div>Are you sure you want to delete this workflow?</div>,
			icon: <WarningFilled />,
			onOk: async () => {
				dispatch(deleteWorkflow({id}));
				const workflowRes = await deleteWorkFlowById(id);
				dispatch(addWorkflow(workflowRes.data));
			},
		});
	};

	return (
		<>
			<Col span={24} >
				<AddWorkflow />
			</Col>
			<Col span={24} style={{paddingTop:"20px", marginBottom:"50px"}}>
				<Card title='My Workflows' className='common-style-wokflowlist'>
					<List
						loading={loading}
						itemLayout='horizontal'
						pagination={{
							pageSize: 5,
						}}
						dataSource={workflows}
						renderItem={(item) => {
							return (
								<List.Item
									style={{opacity: "0.702", backgroundColor:'white', borderRadius:'7px', marginBottom:'5px', paddingLeft:'5px'}}
									actions={[
										<Link to={`/system/workflows/${item.workflowId}/logs`}>
											View Logs
										</Link>,
										<Switch
											id={item.workflowId}
											checked={item.status === '02'}
											onChange={() => handleToggle(item.workflowId)}
										/>,
										<Button
											onClick={() => confirmDelete(item.workflowId)}
											type='link'
											style={{ color: 'red' }}
											icon={
												<DeleteFilled style={{ fontSize: '1.5rem' }} />
											}></Button>,
									]}>
									<Skeleton avatar title={false} loading={loading} active>
										<List.Item.Meta
											avatar={
												<div>
													<Avatar src={item.step1Logo} />
													<CaretRightOutlined />
													<Avatar src={item.step2Logo} />
												</div>
											}
											title={
												<Link to={`/system/editor/${item.workflowId}`}>
													<div>{item.workflowName}</div>
												</Link>
												// <a href={`/system/editor/${item.workflowId}`}><div>{item.workflowName}</div></a>
											}
										/>
									</Skeleton>
								</List.Item>
							);
						}}
					/>
				</Card>
			</Col>
		</>
	);
};

export default WorkflowList;
