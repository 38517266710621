import { api, FormatResponse } from '../index';
import { LogData } from '../models/logs/LogData';

const LOGS_URL = '/api/v1/logs';
const LOG_URL = '/api/v1/log';
const WORKFLOW_URL = '/api/v1/workflows';

export const fetchLogs = async (params) => {
        const logsRes = await api.get(`${LOGS_URL}`, {params});
        return new LogResFormat(logsRes);
};

export const fetchLogsByWorkflowId = async (wrkId, params) => {
        const logsRes = await api.get(`${WORKFLOW_URL}/${wrkId}/logs`, {params});
        return new LogResFormat(logsRes);
};

export const fetchLogById = async (logId) => {
        const logRes = await api.get(`${LOG_URL}/${logId}`);
        return logRes?.data.data;
};



class LogResFormat extends FormatResponse {
        constructor(response){
                super(response);
                this.data = new LogData(this.data);
        }
}