import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout, Menu, Dropdown, Spin } from 'antd';
import { DownOutlined, PicCenterOutlined } from '@ant-design/icons';
import { Cookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import logo from '../images/logo-black-01.svg';
import { useDispatch, useSelector } from 'react-redux';
import { UserIcon, LogoutIcon, HelpCircleIcon } from "../Components/Icons"
import { addSubscriptions } from '../redux/slices/subscriptions';
import { fetchUserProfile } from '../redux/slices/profileSlice';
const cookie = new Cookies();
const { Header } = Layout;

const CustomHeader = (props) => {
	const { HeaderContainer, HeaderLeft, HeaderRight, CustomLink, Logo, IconContainer, LabelContainer } = StyledComponent;
	const { siderHidden, setSiderHidden } = props;
	const { data, loading, error } = useSelector((state) => state.profile);
	const dispatch = useDispatch()
	const history = useHistory();

    useEffect(() => {
        if (!data || Object.keys(data).length === 0) {
            dispatch(fetchUserProfile());
        }
    }, [dispatch, data]);

	const menu = (
		<Menu>
			<Menu.Item key={"profile"}>
				<Link to='/system/profile'>
					<IconContainer>
						<UserIcon />
						Profile
					</IconContainer>
				</Link>
			</Menu.Item>
			<Menu.Item key={"logout"}>
				<Link to="#" onClick={() => handleLogout()}>
					<IconContainer>
						<LogoutIcon />
						Logout
					</IconContainer>
				</Link>
			</Menu.Item>
		</Menu>
	);

	const handleLogout = () => {
		
		cookie.remove('Authorization', { path: '/' });
		history.replace('/login');
		dispatch(addSubscriptions({}))
		localStorage.clear()

	};

	return (
		<HeaderContainer className1='header custom-header'>
			<HeaderLeft>
				<Logo src={logo} siderhidden={+siderHidden} />
			</HeaderLeft>
			
			<HeaderRight>
				<Link to={{pathname: "https://nettverk.io/docs/nettverk/"}} target="_blank" >
					<LabelContainer>
						<HelpCircleIcon />
						<span>Help</span>
					</LabelContainer>
				</Link>

				<Dropdown size='large' overlay={menu}>
					<CustomLink className='ant-dropdown-link' siderHidden={siderHidden}>
						<LabelContainer>
							<UserIcon />
							{
								loading ?(
									<Spin size="small" />
								) : (
									<span>
										{`${data && data?.firstName?.toUpperCase()}`}
										<DownOutlined />
									</span>
								)
							}
							
						</LabelContainer>
					</CustomLink>
				</Dropdown>
			</HeaderRight>
		</HeaderContainer>
	);
};

const StyledComponent = {
	HeaderContainer: styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #FFFDF9 !important;
    border-bottom: 1px solid rgb(215, 213, 210);
		padding: 10px 25px;
	`,
	HeaderLeft: styled.div`
		display: flex;
		gap: 10px;
	`,
	HeaderRight: styled.div`
		display: flex;
		gap: 20px;
	`,
	CustomHeader: styled(Header)`
		width: 100%;
		padding: 0px !important;
		position: fixed;
		top:0,
		left:0,
		z-index: 1000; 
	`,
	Logo: styled.img`
		width: 150px !important;
		height: 100px !important
		display: block;
	`,
	CustomIcon: styled(PicCenterOutlined)`
		font-size: 1.5rem;
		padding: 0.8rem;
		marginrigh: 20rem;
		cursor: pointer;
		float: left;
	`,
	CustomLink: styled.a`
		display: flex;
		gap: 10px;
	`,
	LabelContainer: styled.div`
		display: flex;
		gap: 5px;
		font-size: 16px;
		color:rgba(0, 0, 0, 0.85) !important;
	`,

	IconContainer: styled.div`
		display: flex;
		gap: 5px;
	`,
};

CustomHeader.propTypes = {
	sideBarHidden: PropTypes.bool,
	setSideBarHidden: PropTypes.func,
};

export default CustomHeader;
