import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Col, Row, message, Select, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import countriesJson from '../../data/countries.json';
import {
    fetchUserProfile,
    updateUserProfileThunk,
    changePasswordThunk,
    resetChangePasswordStatus
} from '../../redux/slices/profileSlice';

const cookie = new Cookies();

const Profile = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();

    const { data, loading, error, changePasswordStatus } = useSelector((state) => state.profile);

    const countries = useMemo(
        () =>
            countriesJson?.map((country) => ({
                value: country.name?.common,
                label: country.name?.common,
            })),
        []
    );

    useEffect(() => {
        dispatch(fetchUserProfile());
    }, [dispatch]);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({ user: data });
        }
    }, [data, form]);

    const onFinish = async (values) => {
        try {
            await dispatch(updateUserProfileThunk(values.user)).unwrap();
            message.success('Profile updated successfully!');
            dispatch(fetchUserProfile()); // Re-fetch updated profile
            const prevEmail = localStorage.getItem('email');
            if (prevEmail !== values?.user?.email) {
                cookie.remove('Authorization', { path: '/' });
                localStorage.clear();
                history.replace('/login');
            }
        } catch (error) {
            message.error(error || 'Failed to update profile.');
        }
    };

    const handleChangePassword = async () => {
        try {
            await dispatch(changePasswordThunk(data.email)).unwrap();
            message.success('Password reset link sent successfully!');
        } catch (error) {
            message.error(error || 'Failed to send password reset link.');
        }finally {
            // Clear the password status after 5 seconds
            setTimeout(() => {
                dispatch(resetChangePasswordStatus());
            }, 5000);
        }
    };

    return (
        <Col span={10} offset={1}>
            {loading ? (
                <Spin size="large"/>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <div className="wrapper">
                    <h2>User Profile</h2>
                    <Form
                        form={form}
                        layout="vertical"
                        name="profileForm"
                        onFinish={onFinish}
                        validateMessages={{
                            required: '${label} is required!',
                            types: { email: '${label} is not a valid email!' },
                        }}
                    >
                        <Form.Item
                            name={['user', 'firstName']}
                            label="First Name"
                            rules={[{ required: true }]}
                        >
                            <Input placeholder="First name" />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'lastName']}
                            label="Last Name"
                            rules={[{ required: true }]}
                        >
                            <Input placeholder="Last name" />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'userName']}
                            label="Username"
                        >
                            <Input placeholder="Username" readOnly />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'email']}
                            label="Email"
                            rules={[{ type: 'email' }]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'country']}
                            label="Country"
                            rules={[{ required: true }]}
                        >
                            <Select
                                size="middle"
                                showSearch
                                placeholder="Select User's Country"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={countries}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Row>
                                <Col>
                                    <Button loading={loading} type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Col>
                                <Col offset={1}>
                                    <Button
                                        loading={changePasswordStatus?.loading}
                                        type="secondary"
                                        onClick={handleChangePassword}
                                    >
                                        Change Password
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                    {changePasswordStatus?.message && (
                        <Row
                            className={
                                changePasswordStatus.success
                                    ? 'success-message'
                                    : 'error-message'
                            }
                        >
                            {changePasswordStatus.message}
                        </Row>
                    )}
                </div>
            )}
        </Col>
    );
};

export default Profile;

