import React, { useEffect, useState, useContext } from 'react';
import { Row, Select } from 'antd';
import { PrimaryButton } from '../../Commons';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import { fetchSalesForcePricebooks} from '../../Http/salesforce/triggerObject';
import { isSuccess } from '../../Http';

const ChooseSalesProductPriceBook = () => {
	const { mappings, setActionData} = useContext(WorkflowContext);
	const { appName, next, id } = useContext(StepContext);
	const [options, setOptions] = useState([]);
	const [pricebook, setPricebook] = useState();
	const [pricebookId, setPricebookId] = useState();
	const [preSelectedPricebook, setPreSelectedPricebook] = useState({});
	const [loading, setLoading] = useState(true);

	//1.Get all salesforce pricebooks
	useEffect(() => {
		getSalesForcePricebooks();
	}, []);

	const getSalesForcePricebooks = async () => {
		const saleforcePricebooks = await fetchSalesForcePricebooks();
		console.log({saleforcePricebooks});
		if (isSuccess(saleforcePricebooks)) {
			setOptions(saleforcePricebooks.data);
			setLoading(false);
		}
	};

	//2. When retrieved, fetch the old selected sobject if any
	useEffect(() => {
		if (options.length > 0) getSalesForcePricebook();
	}, [options]);

	const getSalesForcePricebook = async () => {
		mappings?.forEach((v) =>{
			if(v.type === 'CON' && v.field === 'Pricebook2Id'){
				options?.forEach((m)=>{
					if(m.Id === v.value){
						setPricebook(m.Name);
						setPreSelectedPricebook({ type: v.type, field: v.field, value: v.value,  stepId: id, valueStepOrder: v.valueStepOrder })
					}
				})
			}
		})
	};

	const handleSelect = (pId) => {
		setPricebookId(pId);
		options?.forEach((m)=>{
			if(m.Id === pId){
				setPricebook(m.Name);
			}
		})
	};
	const handleMapping = ()=>{
		let MAPPINGS = [];
		MAPPINGS = pricebookId 
		? [...MAPPINGS, { type: 'CON', field: "Pricebook2Id", value: pricebookId,  stepId: id, valueStepOrder: 2 }]
		: [...MAPPINGS, preSelectedPricebook];
		setActionData(MAPPINGS);
		next();
	}

	return (
		<StepContainer title='Choose Price Book'>
			<div>
				{`${appName} Product Pricebook: `} <span className='required'>(required)</span>
			</div>

			<Row justify='center'>
				<Select
					className='fill-width rounded'
					loading={loading}
					onSelect={handleSelect}
					placeholder='Select'
					showSearch
					value={pricebook}
					optionFilterProp='children'>
					{options.map((v, k) => (
						<Select.Option value={v.Id} key={k}>
							{v.Name}
						</Select.Option>
					))}
				</Select>
			</Row>

			<Row justify='center'>
				<PrimaryButton
					className='btn-long'
					size='large'
					onClick={() => handleMapping()}>
					Continue
				</PrimaryButton>
			</Row>
		</StepContainer>
	);
};

export default ChooseSalesProductPriceBook;
