import React, { useContext, useEffect, useState } from 'react';
import { Select, Modal, Spin, Button, Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { PrimaryButton, DisabledButton, SecondaryButton } from '../../Commons';
import { StepContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import { fetchWorkflowById } from '../../Http/workflow';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addLatestWorkflow, getLatestWorkflow } from '../../redux/slices/workflow/latestWorkflow';
import { Step } from '../../Http/models/Step.model';
import './style.scss'
import { api } from '../../Http';


const WoocommerceChooseAccount = () => {

	const dispatch = useDispatch();
	const latestWorkflow = useSelector(getLatestWorkflow);
	const { appName, appCode, accountAuthorized, next } = useContext(StepContext);
	const [isOpen, setIsOpen] = useState(false);
	const [mLoading, setMLoading] = useState(false);
	const [okEnable, setOkEnable] = useState(true);
	const [form] = Form.useForm();
	const [step, setStep] = useState(new Step());
	const [spinning, setSpinning] = useState(false);


	useEffect(()=>{
		if(latestWorkflow.step1.appCode === appCode){
			setStep(latestWorkflow.step1);
		}else if(latestWorkflow.step2.appCode === appCode){
			setStep(latestWorkflow.step2);
		}
	},[accountAuthorized])

	const handleWcCreate = () => {
		form
		  .validateFields()
		  .then((rawFormData) => {
			form.resetFields();
			api
			.post('/api/woocommerce/account', rawFormData, { withCredentials: true })
			.then((resp) => {
				if (resp.data.code === 200) {
					setOkEnable(true);
					adjustLatestWorkflow();
				}
			})
			
		  })
		  .catch((info) => {
			console.log("Validate Failed:", info);
		  });
	  };
	const testCall = ()=>{
		setMLoading(true);
		form
		  .validateFields()
		  .then((rawFormData) => {
			console.log({rawFormData})
			//form.resetFields();
			api
			.post('/api/woocommerce/test-call', rawFormData, { withCredentials: true })
			.then((resp) => {
				if (resp.data.code === 200) {
					setMLoading(false)
					setOkEnable(false);
				}else{
					toast.error(resp.data.message)
				}
			})
			
		  })
		  .catch((info) => {
			console.log("Validate Failed:", info);
		  });

	}


	const adjustLatestWorkflow = async () =>{
		setSpinning(true);
		const workflowRes = await fetchWorkflowById(latestWorkflow.workflowId);
		dispatch(addLatestWorkflow(workflowRes.data));
		setSpinning(false);
		if(latestWorkflow.step1.appCode === appCode){
			setStep(workflowRes.data.step1)
		}else if(latestWorkflow.step2.appCode === appCode){
			setStep(workflowRes.data.step2)
		}
		
		console.log({step});
	}

	const ButtonAuthorized = () => {
		return (
			<DisabledButton disabled className='btn-long secondary-button-color' size='large'>
				<LockOutlined /> Authorized
			</DisabledButton>
		)
	};
	const ButtonAddAccount = () => {
		let fn =
			appCode === 'WC' ? () => setIsOpen(true) : () => adjustLatestWorkflow();
		return (
			<PrimaryButton className='btn-long primary-button-color' size='large' onClick={fn}>
				Add Account
			</PrimaryButton>
		);
	};

	const ButtonContinue = () => (
		<PrimaryButton className='btn-long primary-button-color' size='large' onClick={() => next()}>
			Continue
		</PrimaryButton>
	);
	const ChooseEnvironmentModal = () => {
	
		return (
			<div>
				<Modal
					visible={isOpen}
					title='Choose Woocommerce Environment'
					onCancel={() => setIsOpen(false)}
					onOk={() => {
						handleWcCreate();
						//authRequestFunction(qckbEnv, cb => adjustLatestWorkflow());
						setIsOpen(false);
					}}
					okButtonProps={{ disabled: okEnable }}
				>
                {mLoading ? (
						<div style={{ textAlign: 'center' }}>
							<Spin />
						</div>
						):
						<>
							<div style={{ textAlign: 'center' }}>
								{
									!okEnable ? <h1 style={{color:"green"}}>Connection Test Successfull..!</h1>:""
								}
							</div>
							
							<Form form={form} layout="vertical">
							<Form.Item
								label="Woocommerce Website Url"
								name="instanceURL"
								rules={[
									{ required: true, message: "Please Input Woocommerce Website Url!" },
									{ 
										pattern: "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?",
										message: "Please enter a valid URL!" 
									}
								]}
							>
								<Input addonBefore="https://" placeholder='Url will be like "https://exemple.com"'/>
							</Form.Item>
							<Form.Item 
								name="accessToken" 
								label="Consumer Key"
								rules={[
									{ required: true, message: "Please Input Woocommerce Consumer Key!" }
								]}
								
							>
								<Input />
							</Form.Item>
							<Form.Item 
								name="refreshToken" 
								label="Consumer Secret"
								rules={[
									{ required: true, message: "Please Input Woocommerce Consumer Secret!" }
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								style={{
									textAlign:"center",
									marginTop:"25px"
								}}
							>
								<Button 
									disabled={!okEnable}
									type="primary" 
									htmlType="submit"
									style={{
										backgroundColor:"orangered",
										border:"none"
									}}
									onClick={testCall}
								>Test Connection</Button>
							</Form.Item>
							</Form>
						</>
					}
				</Modal>
			</div>
			
			
		);
	};

	

	return (
		<StepContainer title='Choose Account'>
			{<Spin style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} spinning={spinning} />}
			{`${appName ? appName : 'unknown'} Account: `}
			<span className='required'>(required)</span>
			{step.accountAuthorized ? <ButtonAuthorized /> : <ButtonAddAccount />}
			{step.accountAuthorized && <ButtonContinue />}
			{appCode === 'WC' && <ChooseEnvironmentModal />}
		</StepContainer>
	);
};

export default WoocommerceChooseAccount;
