import React, { useState, useEffect } from 'react';
import { Table, Button, Col, Modal, Select, Form, Input, Checkbox, Spin } from 'antd';
// import { useCookies } from 'react-cookie';
import { api } from '../../Http';
import salesforceLogo from '../../images/salesforce.png';
import quickbooksConnectionButton from '../../images/quickbooks-connect-button.svg';
import woocommerceConnectionButton from '../../images/woocommerce-logo-color-black.svg';
import xeroforceConnectionButton from '../../images/xero-connect-blue-button.svg';
import xeroLogo from '../../images/xero.png';
import quickbooksLogo from '../../images/quickbooks-seeklogo.svg';
import { fetchConnections, fetchUser, removeConnectionById } from '../../Http/connections';
import { useDispatch, useSelector } from 'react-redux';
import { addConnections, getAllConnections, removeConnection } from '../../redux/slices/connections';
import { ENVIRONMENT } from '../../Http/API';
import { flatten } from '../../Commons/utils';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const callback = ENVIRONMENT;

export const Connection = () => {

	const dispatch = useDispatch();
	const SFDC_SANDBOX = 'test';
	const SFDC_PROD = 'login';
	const SFDC_CALLBACK = callback.sfdc_callback;
	const SFDC_CLIENT_ID = callback.sfc_client_id;
	let environment = SFDC_PROD;
	// const [cookies] = useCookies(['Authorization']);
	// const [appData, setAppData] = useState(data);
	const appData = useSelector(getAllConnections);
	const [loading, setLoading] = useState(false);
	const [mLoading, setMLoading] = useState(false);
	const [form] = Form.useForm();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [deleteModalConfirmLoading, setDeleteModalConfirmLoading] = useState(false);
	const [deleteId, setDeleteId] = useState(0);
	const [sfdcModalOpen, setSfdcModalOpen] = useState(false);
	const [qckbModalOpen, setQckbModalOpen] = useState(false);
	const [wcModalOpen, setWcModalOpen] = useState(false);
	const [okEnable, setOkEnable] = useState(true);
	const [qckbEnv, setQckbEnv] = useState('prod');
	const quickbooksEnvironments = [
		{
			envTitle:"Production Environment",
			envValue:"prod"
		},
		{
			envTitle:"Sandbox Environment",
			envValue:"sandbox"
		},
	]
	const columns = [
		{
			title: 'App Name',
			dataIndex: 'app',
			key: 'acc_app',
			render: text => <a>{text === 'SFDC' ? <img width='40px' src={salesforceLogo} alt="Saleforce..."/> : text === 'WC' ? <img width='150px' src={woocommerceConnectionButton} alt="woocommerce..."/> :<img width='32px' src={xeroLogo} alt="Xero..." /> }</a>,
		},
		{
			title: 'Name',
			dataIndex: 'instanceName',
			render: text => <span>{text}</span>,
		},
		{
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			render: text => <span>{text === '01' ? 'Active' : 'Deactivated'}</span>
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, datum) => (
				<>
					{
						datum.refreshToken === null 
						? 
						<Button style={{padding:'0px'}} size="large" onClick={()=>addConnection(datum.app)}>
							{
								datum.app === 'XERO'
								?
								<img style={{width:'100%', height: '100%'}} src={xeroforceConnectionButton} alt="Xero..." />
								:
								datum.app === 'WC'
								?
								<span style={{padding: '5px 20px 5px 20px'}}>
									<img width="100px"style={{}} src={woocommerceConnectionButton} alt="Quickbooks..."/> Connect
								</span>
								:
								<span style={{display:'block', padding:'3px', backgroundColor:'#0D9DDA', color: 'white', width:'225px', height:'38px', borderRadius:'3px', fontSize:'18px', border:'none'}}>Connect to Salesforce</span>
								}
						</Button>
						:
						<Button style={{padding:0}} size="large" onClick={()=> deleteAppAccount(datum.id)}>
							{
								datum.app === 'XERO'
								?
								<img style={{width:'100%', height: '100%'}} src="https://developer.xero.com/static/images/documentation/ConnectToXero2019/disconnect-white.svg" alt="Xero..." />
								:
								datum.app === 'WC'
								?
								<span style={{padding: '5px 20px 5px 20px'}}>
									<img width={'100px'} style={{}} src={woocommerceConnectionButton} alt="Quickbooks..."/>Disconnect
								</span>
								:
								<span style={{padding: '5px 44px 5px 44px'}}>
									<img width='30px' src={salesforceLogo} alt="Saleforce..."/>  Disconnect
								</span>
							}
						</Button>
					}

				</>

			),
		},
	];

	useEffect(()=>{
		getConnections();
	},[]);

	const getConnections = async () => {
		const user = await fetchUser();
		//console.log({user});

		const connectionsRes = await fetchConnections();
		if(connectionsRes && connectionsRes.data) {
			//console.log({connectionsResData: connectionsRes.data});
			// const updateData = [];
			connectionsRes.data.map(datum => {
				datum.key = datum.id;
			});
			dispatch(addConnections(connectionsRes.data.filter(item=>item.app !== 'QCKB')));
		};
	};

	const disconnectItem = async (id) => {
		const connectionRes = await removeConnectionById(id);

		//console.log({connectionRes});
		setDeleteModalOpen(false);
		setDeleteModalConfirmLoading(false);
		if(connectionRes && connectionRes.data) {
				dispatch(removeConnection(id));
				// setAppData(updatedData);
				setLoading(false);
				setDeleteId(0);
				//setRefetch(!refetch);
				//console.log('disconnected');
		} else {
			//console.log('not disconnected');
		}

	};

	const showPopconfirm = () => {
		setDeleteModalOpen(true);
		setLoading(true);
	};

	const handleOk = () => {
		const id = deleteId;
		////console.log({id});
		setLoading(false);
		setDeleteModalConfirmLoading(true);
		disconnectItem(id);
	};

	const handleCancel = () => {
		//console.log('Clicked cancel button');
		setDeleteModalOpen(false);
		setLoading(false);
	};

	const deleteAppAccount = (id) => {
		showPopconfirm();
		setDeleteId(id);
		//console.log({id});
		setLoading(true);
	};

	const addConnection = (app) => {
		// app === 'XERO' ? openXeroConnection() : app === 'QCKB' ? openQuickbooksConnection() : setSfdcModalOpen(true);
		app === 'XERO' 
		? openXeroConnection() 
		: app === 'WC' ? setWcModalOpen(true) 
		: setSfdcModalOpen(true);
	};

	const openXeroConnection = () => {
		api
		.get('/api/xero')
		.then((resp) => {
			//console.log({resp});
			if (resp.data.code === 0) {
				// TODO: check for new window allow
				//console.log({xeroConnection: resp.data})
				const popWindow = window.open(
					resp.data.data,
					'newwindow',
					'width=800,height=600'
				);
				const syncWindow = setInterval(() => {
					if(popWindow.closed) {  
						getConnections();
						clearInterval(syncWindow); 
					}  
				}, 500);
			}
		})
		.catch((e) => console.log(e));

	};

	const openSaleForceConnection = (environment) => {
		//console.log({environment});
		window.SFDC_ENV = environment;
		const url = `https://${environment}.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${SFDC_CLIENT_ID}&redirect_uri=${SFDC_CALLBACK}`;
		const popWindow = window.open(url, 'newwindow', 'width=1000,height=600');
		//console.log({popWindow: popWindow});
		
		const syncWindow = setInterval(() => {
			if(popWindow.closed) {  
				getConnections();
				clearInterval(syncWindow); 
			}  
		}, 500); 

	};
	const handleWcCreate = () => {
		form
		  .validateFields()
		  .then((rawFormData) => {
			form.resetFields();
			api
			.post('/api/woocommerce/account', rawFormData, { withCredentials: true })
			.then((resp) => {
				if (resp.data.code === 200) {
					setLoading(false);
					getConnections();
					setWcModalOpen(false);
					toast.success(resp.data.message)
					setOkEnable(true);
				}else{
					setLoading(false);
					getConnections();
					setWcModalOpen(false);
					toast.error(resp.data.message)
				}
			})
			
		  })
		  .catch((info) => {
			console.log("Validate Failed:", info);
		  });
	  };
	const testCall = ()=>{
		setMLoading(true);
		form
		  .validateFields()
		  .then((rawFormData) => {
			console.log({rawFormData})
			//form.resetFields();
			api
			.post('/api/woocommerce/test-call', rawFormData, { withCredentials: true })
			.then((resp) => {
				if (resp.data.code === 200) {
					toast.success("Connection Test Successful...")
					setMLoading(false)
					setOkEnable(false);
				}else{
					toast.error(resp.data.message)
				}
			})
			
		  })
		  .catch((info) => {
			console.log("Validate Failed:", info);
		  });

	}

	return (
		<Col span={24}>
			<ToastContainer />
			<Modal title="Confirmation For Deleting" okType='danger' confirmLoading={deleteModalConfirmLoading} visible={deleteModalOpen} okText="Confirm Disconnect" onOk={handleOk} onCancel={handleCancel}>
				<p>Are you sure to disconnect this connection ?</p>
			</Modal>
			<Table loading={loading} columns={columns} dataSource={appData} />
			{/* Saleforce */}
			<Modal
				visible={sfdcModalOpen}
				title='Choose Salesforce Environment'
				onCancel={() => setSfdcModalOpen(false)}
				onOk={() => {
					setSfdcModalOpen(false);
					openSaleForceConnection(environment);
				}}>
				<Select
					defaultValue={environment}
					onChange={(v) => (environment = v)}
					className='fill-width'>
					<Select.Option value={SFDC_PROD}>
						Production Environment
					</Select.Option>
					<Select.Option value={SFDC_SANDBOX}>
						Sandbox Environment
					</Select.Option>
				</Select>
			</Modal>
			{/* woocommerce */}
			<Modal
				loading={loading}
				visible={wcModalOpen}
				title='Input Woocommerce Credientials'
				onCancel={() => setWcModalOpen(false)}
				onOk={() => {
					handleWcCreate();
					setLoading(true);
				}}
				okButtonProps={{ disabled: okEnable }}
				>
					{mLoading ? (
						<div style={{ textAlign: 'center' }}>
							<Spin />
						</div>
						):
						<Form form={form} layout="vertical">
							<Form.Item
								label="Woocommerce Website Url"
								name="instanceURL"
								rules={[
									{ required: true, message: "Please Input Woocommerce Website Url!" },
									{ 
										pattern: "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?",
										message: "Please enter a valid URL!" 
									}
								]}
							>
								<Input addonBefore="https://" placeholder='Url will be like "https://exemple.com"'/>
							</Form.Item>
							<Form.Item 
								name="accessToken" 
								label="Consumer Key"
								rules={[
									{ required: true, message: "Please Input Woocommerce Consumer Key!" }
								]}
								
							>
								<Input />
							</Form.Item>
							<Form.Item 
								name="refreshToken" 
								label="Consumer Secret"
								rules={[
									{ required: true, message: "Please Input Woocommerce Consumer Secret!" }
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								style={{
									textAlign:"center",
									marginTop:"25px"
								}}
							>
								<Button 
									disabled={!okEnable}
									type="primary" 
									htmlType="submit"
									style={{
										backgroundColor:"orangered",
										border:"none"
									}}
									onClick={testCall}
								>Test Connection</Button>
							</Form.Item>
						</Form>
					}
				    
			</Modal>
		</Col>
	);
};
