import { api, FormatResponse } from '../index';
const SFDC_OBJECTS_BASE_URL = '/api/salesforce/objects';
const GET_SOBJECT_URL = '/api/v1/steps/salesforce';
const SFDC_PRICEBOOKS_URL = '/api/salesforce/price-books';

export const fetchSalesForceObjects = async () => {
        const saleforceObjectsRes = await api.get(SFDC_OBJECTS_BASE_URL);
        return new FormatResponse(saleforceObjectsRes);
};

export const fetchSalesForcePricebooks = async () => {
        const saleforcePricebooksRes = await api.get(SFDC_PRICEBOOKS_URL);
        return new FormatResponse(saleforcePricebooksRes);
};

export const fetchSalesForceStepObject = async (id) => {
        const salesforceStepRes = await api.get(`${GET_SOBJECT_URL}/${id}`);
        return new FormatResponse(salesforceStepRes);
};