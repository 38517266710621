import { Col, Table, Row, Button, Result, Select, Input, Form, Modal  } from 'antd';
import { CaretRightOutlined, CaretLeftOutlined, DeleteFilled, WarningFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { TableWrapper } from '../../Commons';
import { useHistory } from 'react-router-dom';
import { startCase } from 'lodash';
import './style.scss';
import { fetchAllXeroConnections, removeXeroConnectionByAdmin } from '../../Http/xero';
      
const AllXeroConnections = (props) => {
	const [data, setData] = useState([]);
	const [refresh,setRefresh] = useState(true);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const history = useHistory();

	useEffect(() => {
		getAllXeroConnections();
	}, [refresh]);


	const getAllXeroConnections = async () => {
		setLoading(true);
		const connectionsRes = await fetchAllXeroConnections();
		//console.log({connectionsRes})
		if(connectionsRes) setLoading(false);
		if(connectionsRes.code === 0){
			setData(connectionsRes.data);
			setError(false);
		} else {
			setError(true);
		}
	};

	const extractCols = () => {
		if (!data || data.length === 0) return [];

		let jsonKeys = Object.keys(data[0]);
		let columns = [];

		jsonKeys.forEach((v) => {
			if(v === 'id' || v === 'authEventId' || v === 'createdDateUtc' || v === 'updatedDateUtc') {
				// escape
			} else {
				let cell = {
					title: startCase(v) ,
					dataIndex: v,
					key: v,
					align: 'center',
					ellipsis: true,
				};
				columns.push(cell);
			}
		});
		const action = {
			title: 'Action',
			key: 'operation',
			fixed: 'right',
			width: 300,
			render: (record) => {
				//console.log({record})
				return(
				<div style={{display:"flex",justifyContent:"space-around"}}>
					<Button
						onClick={() => confirmDelete(record?.id)}
						type='link'
						style={{ color: 'red' }}
						icon={
							<DeleteFilled style={{ fontSize: '1.5rem' }} />
							}></Button>
					{/* <Button style={{background: '#252B6A', borderColor: '#252B6A'}} type="primary" danger onClick={()=>history.replace(`/system/workflows/user/${record.id}`)}>View Workflows</Button> */}
				</div>)
			},
		};
		columns.push(action);
		return columns;
	};


	const extractRows = () => {
		if (!data || data.length === 0) return [];
		let rows = [];
		data.forEach((v, k) => {
			rows.push({ ...v, key: k });
		});
		return rows;
	};
	const confirmDelete = (id) => {
		//console.log({connectionId:id})
		Modal.confirm({
			title: 'Revoke The Connection',
			content: <div>Are you sure you want to delete this connection?</div>,
			icon: <WarningFilled />,
			onOk: async () => {
				const res = await removeXeroConnectionByAdmin(id);
				if(res.code === 0){
					setRefresh(!refresh)
				}
			},
		});
	};


	return (
		
		<>
			<TableWrapper style={{marginBottom:"70px", padding:0, marginTop:0}} className='wrapper'>
				{!error ? (
					<>
					<Table 
						loading={loading}
						scroll={{ x: 'max-content' }}
						pagination={false}
						dataSource={extractRows()}
						columns={extractCols()}
					>

					</Table>
					</>
				) : (
					<Result
						status='404'
						title='404'
						subTitle='Sorry, the page you visited does not exist.'
						extra={
							<Button
								type='primary'
								onClick={() => history.replace('/system/workflows')}>
								Back Home
							</Button>
						}
					/>
				)}
			</TableWrapper>
		</>
	);
};

export default AllXeroConnections;
