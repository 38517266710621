import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import jwt_decode from "jwt-decode";
import Routes from './routes';
import { Provider, useDispatch } from 'react-redux';
import { store } from './redux/store';
import './style.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Cookies } from 'react-cookie';

// for pwa
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ENVIRONMENT } from './Http/API';
import { addSubscriptions } from './redux/slices/subscriptions';
import { Spin } from 'antd';
const cookie = new Cookies();


const App = () => {
  const [refresh, setRefresh] = useState(true);
  const dispatch = useDispatch();

  const handleLogout = () => {
		cookie.remove('Authorization', { path: '/' });
		dispatch(addSubscriptions({}));
    localStorage.clear();
    window.location.href="/login";
	};

  const checkForInactivity = ()=>{
    const expireTime = localStorage.getItem("expirationTime");
    if(expireTime !== null){
      if(expireTime < Date.now()){
        handleLogout();
      }
    }
    setRefresh(false);
  }

  const updateExpireTime = ()=>{
    const token = localStorage.getItem("token");
    if(token){
      const expireTime = Date.now() + 7200000;
      localStorage.setItem("expirationTime", expireTime);
    }
    
  }

  useEffect(()=>{
    const interval = setInterval(()=>{
        checkForInactivity();
    }, 500);
    return ()=>clearInterval(interval);
  },[])

  useEffect(()=>{
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return ()=>{
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);

    }
  },[])

  if(refresh){
    return <div className='spinner'>
      <Spin size='large'/>
    </div>
  }

  const stripePromise = loadStripe(ENVIRONMENT.stripe_public_key);
  return <Elements stripe={stripePromise}>
    <Routes />
  </Elements>;
};



const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>,
  // </React.StrictMode>
);




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
