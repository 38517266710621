import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Row, Col, Select, Form, message, Card, Radio, Divider, Input, Button } from 'antd';
import { PrimaryButton } from '../../Commons';
import { isSuccess } from '../../Http';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import { keys as keycreate, isObject } from 'lodash';
import './style.scss';
import { requiredRule } from '../../Commons/utils';
import { fetchSalesForceObjects } from '../../Http/salesforce/triggerObject';
import { delegateSaleForce } from '../../Http/salesforce/delegate';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const FindDataWithTriggerForWcCustomer = () => {
    const { next, id } = useContext(StepContext);
    const { sampleData, sampleData2, setSampleData, setSampleData2, actionData, setActionData, SObject, setSObject, additionalQuery, setAdditionalQuery, } = useContext(WorkflowContext);
    const [loading, setLoading] = useState(true);
    const [accountFields, setAccountFields] = useState([]);
    const [contactFields, setContactFields] = useState([]);
    const [returnMap, setReturnMap] = useState(false);
    const [form] = Form.useForm();
    
    const wcAccountSearchFields = [
        {key: "billing_company", value:"billing.company"},
        {key: "id", value:"id"}
    ]
    const wcContactSearchFields = [
        {key: "first_name", value:"first_name"},
        {key: "last_name", value:"last_name"},
        {key: "email", value:"email"},
    ]

    //old code

    const handleAccount = async (obj) => {
        setSObject(obj?.name);
        setLoading(false);
        
        const params = { uri: obj?.urls.describe };
        
        try {
            const resp = await delegateSaleForce(params);
            if (isSuccess(resp)) {
                const fields = resp.data.fields.map((v) => v.name);
                setAccountFields(fields);
                setSampleData(resp.data);
            }
        } catch (delegateError) {
            console.log({ delegateError });
        }
        
        getAllDelegateSalesForce(obj?.urls?.sobject);
    };
    
    const handleContact = async (obj) => {
        setSObject((prev) => prev + "|" + obj?.name);
        setLoading(false);
        
        const params = { uri: obj?.urls.describe };
        
        try {
            const resp = await delegateSaleForce(params);
            if (isSuccess(resp)) {
                const fields = resp.data.fields.map((v) => v.name);
                setContactFields(fields);
                setSampleData2(resp.data);
            }
        } catch (delegateError) {
            console.log({ delegateError });
        }
        
        getAllDelegateSalesForce2(obj?.urls?.sobject);
    };
    
    const getSalesForceObjects = useCallback(async () => {
        const salesforceObjectsRes = await fetchSalesForceObjects();
    
        if (isSuccess(salesforceObjectsRes)) {
            await Promise.all(salesforceObjectsRes?.data.map(async (obj) => {
                if (obj.label === "Account") {
                    await handleAccount(obj);
                } else if (obj.label === "Contact") {
                    await handleContact(obj);
                }
            }));
        }
    }, []);
    

    useEffect(() => {
        getSalesForceObjects()
    }, [getSalesForceObjects])

    const params = (uri) => ({ uri: uri });
    const getAllDelegateSalesForce = async (sObjUrl) => {
		const delegateSalesForceRes =  await delegateSaleForce( params(sObjUrl) );
		const data = delegateSalesForceRes.data.recentItems;
		if(! data[0]) return message.error('no data found');
		const delegateSalesForceAttrRes = await delegateSaleForce( params(data[0].attributes.url) );
		if(delegateSalesForceAttrRes) setSampleData(delegateSalesForceAttrRes?.data);
	};
    const getAllDelegateSalesForce2 = async (sObjUrl) => {
		const delegateSalesForceRes =  await delegateSaleForce( params(sObjUrl) );
		const data = delegateSalesForceRes.data.recentItems;
		if(! data[0]) return message.error('no data found');
		const delegateSalesForceAttrRes = await delegateSaleForce( params(data[0].attributes.url) );
		if(delegateSalesForceAttrRes) setSampleData2(delegateSalesForceAttrRes.data);
	};

    const handleSubmit = () => {
        const objArr = SObject?.split("|");
        const parentObj = objArr[0];
        const childObj = objArr[1];
        
        console.log({sampleData, sampleData2})
        form
            .validateFields()
            .then((val) => {

                let accountQueryUrl = null;
                let contactQueryUrl = null;
                if (val?.fields?.length) {
                    const conditions = val.fields
                        .filter(f => f?.field && f?.searchValue) // Only valid fields
                        .map(f => `${f.field}+=+$${f.searchValue}`) // Create conditions
                        .join('+AND+'); // Combine conditions (use AND or OR as required)
                
                    if (conditions) {
                        accountQueryUrl = `{instance_url}/services/data/v61.0/query/?q=SELECT+Id+FROM+${parentObj}+WHERE+${conditions}`;
                    }
                }
                if(val?.conFields?.length){
                    const conditions = val.conFields
                        .filter(f => f?.field && f?.searchValue) // Only valid fields
                        .map(f => `${f.field}+=+$${f.searchValue}`) // Create conditions
                        .join('+AND+'); // Combine conditions (use AND or OR as required)
                
                    if (conditions) {
                        contactQueryUrl = `{instance_url}/services/data/v61.0/query/?q=SELECT+Id+FROM+${childObj}+WHERE+${conditions}`;
                    }
                }
                setAdditionalQuery(accountQueryUrl + "|" + contactQueryUrl)
                next();
            })
            .catch((e) => console.log(e));
    };

    const DynamicFieldList = ({ fields, add, remove, searchFields, searchValues, fieldTitle, valueTitle  }) => (
        <>
            {fields?.map((field, index) => (
                <div key={field.key}>
                    <Divider>{index > 0 && "AND" }</Divider>
                    <Form.Item label={fieldTitle} name={[index, "field"]} rules={[{ required: true, message: 'Field is required.' }]}>
                        <Select>
                            {searchFields?.map((field, key) => (
                                <Select.Option value={field} key={key}>
                                    {field}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={valueTitle} name={[index, "searchValue"]} rules={[{ required: true, message: 'Search value is required.' }]}>
                        <Select>
                            {searchValues?.map((field, key) => (
                                <Select.Option value={field.value} key={key}>
                                    {field.key}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {fields.length > 1 && (
                        <Button type="danger" icon={<MinusCircleOutlined />} onClick={() => remove(field.name)}>
                            Remove Field
                        </Button>
                    )}
                </div>
            ))}
            <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                    <PlusOutlined /> Add Field
                </Button>
            </Form.Item>
        </>
    );
    return (
        <StepContainer title={returnMap ? 'Find Contact Record': 'Find Account Record'}>
            <Form form={form} layout='vertical'>
                <Row  justify='center'>
                   
                </Row>
                <div hidden={returnMap}>
                     <Form.List name="fields">
                        {(fields, { add, remove }) => (
                            <DynamicFieldList 
                                fields={fields} 
                                add={add} 
                                remove={remove} 
                                searchFields={accountFields.sort()} 
                                searchValues={wcAccountSearchFields}
                                fieldTitle={"Select Account Field"}
                                valueTitle={"Select WooCommerce Field"}
                            />
                        )}
                     </Form.List>
                </div>

                <PrimaryButton hidden={returnMap} className='btn-long' size='large' htmlType='button' onClick={() => setReturnMap(true)}>
                        Continue
                </PrimaryButton>

                <div hidden={!returnMap}>
                    <Form.List name="conFields">
                            {(fields, { add, remove }) => (
                                <DynamicFieldList 
                                    fields={fields} 
                                    add={add} 
                                    remove={remove} 
                                    searchFields={contactFields.sort()} 
                                    searchValues={wcContactSearchFields}
                                    fieldTitle={"Select Contact Field"}
                                    valueTitle={"Select WooCommerce Field"}
                                />
                            )}
                     </Form.List>
                    <PrimaryButton hidden={!returnMap} className='btn-long' size='large' onClick={handleSubmit}>
                        Continue
                    </PrimaryButton>
                </div>
              
            </Form>
        </StepContainer>
    );
};

export default FindDataWithTriggerForWcCustomer;
