import { Card, Col, Row, Form, Input, Button, Layout } from 'antd';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { SecondaryButton } from '../../Commons';
import { requiredRule } from '../../Commons/utils';
import logo from '../Login/images/logo_v_png.png';
import loginBG from '../Login/images/bg.jpg';
import './style.scss';
import { api } from '../../Http';
import { CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Content } from 'antd/lib/layout/layout';

const ChangePassPage = () => {
	const { Background } = StyledComponents;
	const [form] = Form.useForm();
	const [resp, setResponse] = useState(undefined);

	const onSubmit = (v) => {
		
		if (v.password !== v.confirmPassword) {
			setResponse({ msg: 'Password does not match!', success: false });
			return;
		}

		let token = new URLSearchParams(window.location.search).get('token');
		api
			.post('/account/change-password', v, {
				withCredentials: false,
				params: { token: token },
			})
			.then((resp) => {
				if (resp.data.code === 0) {
					setResponse({ msg: resp.data.message, success: true });
				} else {
					setResponse({ msg: resp.data.message, success: false });
				}
			})
			.catch((e) => console.log(e));
	};

	const styles = {
		error: {
			textAlign: 'center',
			margin: '1rem',
			fontSize: '22px',
			fontWeight: 'bold',
			color: '#cf4500',
		},
		success: {
			textAlign: 'center',
			margin: '1rem',
			fontSize: '22px',
			fontWeight: 'bold',
			color: '#22BB33',
		},
		login:{
			textAlign: 'center',
			margin: '1rem',
			fontSize: '22px',
			color: 'blue',
		}
	};

	return (
		<Layout>
			<Content>
				<Background justify='center' align='middle'>
					<Col xs={22} sm={16} md={12} lg={10} xl={8} xxl={7}>
						<Card title='Change Password' className='change-pass-card'>
								<a href="https://nettverk.io/" target="_blank" rel='noreferrer'>
								<img className='logo' src={logo} alt='logo'/>
								</a>
							{!resp || !resp.success ? (
								<React.Fragment>
									{resp && resp.msg && <div style={styles.error}>{resp.msg}</div>}
									<Form form={form} layout='vertical' onFinish={(e) => onSubmit(e)} style={{ textAlign: 'center', marginTop: '2rem' }}>
										<Form.Item
											name='password'
											required
											rules={[requiredRule]}>
											<Input.Password className='txt-field' placeholder='New Password..'></Input.Password>
										</Form.Item>
										<Form.Item
											name='confirmPassword'
											required
											rules={[requiredRule]}>
											<Input.Password className='txt-field' placeholder='Confirm Password..'></Input.Password>
										</Form.Item>
										<Form.Item>
											<SecondaryButton onClick={onSubmit} style={{borderRadius:"5px", width:"100%"}} className='nv-btn' htmlType='submit' type='primary' size='large' block>Submit</SecondaryButton>
										</Form.Item>
									</Form>
								</React.Fragment>
							) : (
								<div>
									{' '}
									<div style={styles.success}>
										<CheckCircleOutlined /> {resp.msg}{' '}
									</div>
									<div style={styles.login}>
										<Link to={`/login`}>
											Go to Login
										</Link>
									</div>
								</div>
							)}
						</Card>
					</Col>
				</Background>
			</Content>
		</Layout>
	);
};

export default ChangePassPage;
const StyledComponents = {
	Background: styled(Row)`
		height: 100vh;
		background-image: url(${loginBG});
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	`,
	SubmitButton: styled(Button)``,
};
