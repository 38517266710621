import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Row, Col, Select, Form, message } from 'antd';
import { PrimaryButton } from '../../Commons';
import { isSuccess } from '../../Http';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import './style.scss';
import { requiredRule } from '../../Commons/utils';
import { fetchSalesForceObjects } from '../../Http/salesforce/triggerObject';
import { delegateSaleForce } from '../../Http/salesforce/delegate';

const ProductFindWithTriggerForWoocommerce = () => {
    const { next, id } = useContext(StepContext);
    const { setSampleData, actionData, setActionData, setSObject } = useContext(WorkflowContext);
    const [loading, setLoading] = useState(true);
    const [fields, setFields] = useState([]);
    const [form] = Form.useForm();
    
    const optionsnew = [
        {key: "Id", value:"id"},
        {key: "Sku", value:"sku"}
    ]

    //old code
    const getSalesForceObjects = useCallback(async () => {

        const salesforceObjectsRes = await fetchSalesForceObjects();

        if (isSuccess(salesforceObjectsRes)) {
            salesforceObjectsRes?.data.map((obj, key)=>{
                if(obj.label === "Product"){
                    setSObject(obj?.name+"|PricebookEntry");
                    setLoading(false);
                    const params = { uri: obj?.urls.describe };
                    delegateSaleForce(params)
                        .then((resp) => {
                            if (isSuccess(resp)) {
                                let fields = resp.data.fields.map((v) => v.name);
                                setFields(fields);
                            }
                        })
                        .catch((delegateError) => console.log({ delegateError }));
                    getAllDelegateSalesForce(obj?.urls?.sobject);
                }
            })
            
        }
    }, []);

    useEffect(() => {
        getSalesForceObjects()
    }, [getSalesForceObjects])

    const params = (uri) => ({ uri: uri });
    const getAllDelegateSalesForce = async (sObjUrl) => {
		const delegateSalesForceRes =  await delegateSaleForce( params(sObjUrl) );
		const data = delegateSalesForceRes.data.recentItems;
		if(! data[0]) return message.error('no data found');
		const delegateSalesForceAttrRes = await delegateSaleForce( params(data[0].attributes.url) );
		if(delegateSalesForceAttrRes) setSampleData(delegateSalesForceAttrRes.data);
	};

    const handleSubmit = () => {
        form
            .validateFields()
            .then((val) => {
                let MAPPINGS = [];
                MAPPINGS =[...actionData, { type: 'LKUP', field: val?.field, value: val?.searchValue, stepId: id, valueStepOrder: 1 }]
                setActionData(MAPPINGS);
                next();
            })
            .catch((e) => console.log(e));
    };
    
    const CustomSelect = (props) => {
        const { title, name, onSelect } = props;
        return (
            <Row>
                <div>
                    {title}
                    <span className='required'>(required)</span>
                </div>
                <Col span={24}>
                    <Form.Item name={name} rules={[requiredRule]}>
                        <Select
                            className='fill-width rounded'
                            loading={loading}
                            onSelect={onSelect}
                            placeholder='Select'
                            showSearch
                            optionFilterProp='children'>
                            {props.children}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    return (
        <StepContainer title='Find Product Record'>
            <Form form={form} layout='horizontal'>
                <CustomSelect name='field' title='Select Product Field for search :'>
                    {fields.map((v, k) => (
                        <Select.Option value={v} key={k}>
                            {v}
                        </Select.Option>
                    ))}
                </CustomSelect>
                <CustomSelect name='searchValue' title='Woocommerce field :'>
                    {optionsnew.map((v, k) => (
                        <Select.Option value={v.value} key={k}>
                            {v.key}
                        </Select.Option>
                    ))}
                </CustomSelect>
            </Form>

            <Row justify='center' className='mt-2'>
                <PrimaryButton className='btn-long' size='large' onClick={handleSubmit}>
                    Continue
                </PrimaryButton>
            </Row>
        </StepContainer>
    );
};

export default ProductFindWithTriggerForWoocommerce;
