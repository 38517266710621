import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Row, Col, Select, Form, message } from 'antd';
import { PrimaryButton } from '../../Commons';
import { isSuccess } from '../../Http';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import './style.scss';
import { requiredRule } from '../../Commons/utils';
import { fetchSalesForceObjects } from '../../Http/salesforce/triggerObject';
import { delegateSaleForce } from '../../Http/salesforce/delegate';

const FindSfAccountForWcOrder = () => {
    const { next, id } = useContext(StepContext);
    const { setSampleData, setSObject, setAdditionalQuery, setSampleData2 } = useContext(WorkflowContext);
    const [loading, setLoading] = useState(true);
    const [fields, setFields] = useState([]);
    const [form] = Form.useForm();
    
    const optionsnew = [
        {key: "customer_id", value:"customer_id"},
        //{key: "billing_company", value:"billing.company"}
    ]

    //old code
    const getSalesForceObjects = useCallback(async () => {

        const salesforceObjectsRes = await fetchSalesForceObjects();

        if (isSuccess(salesforceObjectsRes)) {
            salesforceObjectsRes?.data.map((obj, key)=>{
                if(obj.label === "Opportunity"){
                    setSObject(obj?.name); //Opportunity
                    setLoading(false);
                    const params = { uri: obj?.urls.describe };
                    delegateSaleForce(params)
                        .then((resp) => {
                            if (isSuccess(resp)) {
                                let fields = resp.data.fields.map((v) => v.name);
                                setFields(fields);
                                //console.log({sampleData1:resp.data})
                                //setSampleData(resp.data)
                            }
                        })
                        .catch((delegateError) => console.log({ delegateError }));
                    getAllDelegateSalesForce(obj?.urls?.sobject);
                }
                if(obj.label === "Account"){
                    setLoading(false);
                    const params = { uri: obj?.urls.describe };
                    delegateSaleForce(params)
                        .then((resp) => {
                            if (isSuccess(resp)) {
                                let fields = resp.data.fields.map((v) => v.name);
                                setFields(fields);
                            }
                        })
                        .catch((delegateError) => console.log({ delegateError }));
                }
            })
            
        }
    }, []);

    useEffect(() => {
        getSalesForceObjects()
    }, [getSalesForceObjects])

    const params = (uri) => ({ uri: uri });
    const getAllDelegateSalesForce = async (sObjUrl) => {
		const delegateSalesForceRes =  await delegateSaleForce( params(sObjUrl) );
		const data = delegateSalesForceRes.data.recentItems;
		if(! data[0]) return message.error('no data found');
		const delegateSalesForceAttrRes = await delegateSaleForce( params(data[0].attributes.url) );
		if(delegateSalesForceAttrRes) setSampleData(delegateSalesForceAttrRes.data);

        //sampleData2
        const opportunityLineItemUrl = '/services/data/v48.0/query?q=SELECT+Id,+OpportunityId,+Product2Id,+Quantity,+UnitPrice,+TotalPrice+FROM+OpportunityLineItem';
        const opportunityLineItemRes=  await delegateSaleForce( params(opportunityLineItemUrl) );
        const lineItemData = opportunityLineItemRes?.data.records;
        if(! lineItemData[0]) return message.error('no lineitem data found');
        const opportunityLineItemAttrRes = await delegateSaleForce( params(lineItemData[0].attributes.url) );
		if(opportunityLineItemAttrRes) setSampleData2(opportunityLineItemAttrRes.data);

	};

    const handleSubmit = () => {
        form
            .validateFields()
            .then((val) => {
                console.log({val});
                setSObject((prev) => prev + "|" + "Account");
                const accountQueryUrl = `{instance_url}/services/data/v61.0/query/?q=SELECT+Id+FROM+Account+WHERE+${val?.field}+=+${'$'+val?.searchValue}`;
                setAdditionalQuery(accountQueryUrl);
                next();
            })
            .catch((e) => console.log(e));
    };
    
    const CustomSelect = (props) => {
        const { title, name, onSelect } = props;
        return (
            <Row>
                <div>
                    {title}
                    <span className='required'>(required)</span>
                </div>
                <Col span={24}>
                    <Form.Item name={name} rules={[requiredRule]}>
                        <Select
                            className='fill-width rounded'
                            loading={loading}
                            onSelect={onSelect}
                            placeholder='Select'
                            showSearch
                            optionFilterProp='children'>
                            {props.children}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    return (
        <StepContainer title='Find Account Record'>
            <Form form={form} layout='horizontal'>
                <CustomSelect name='field' title='Select Salesforce Account Field:'>
                    {fields.map((v, k) => (
                        <Select.Option value={v} key={k}>
                            {v}
                        </Select.Option>
                    ))}
                </CustomSelect>
                <CustomSelect name='searchValue' title='Woocommerce field :'>
                    {optionsnew.map((v, k) => (
                        <Select.Option value={v.value} key={k}>
                            {v.key}
                        </Select.Option>
                    ))}
                </CustomSelect>
            </Form>

            <Row justify='center' className='mt-2'>
                <PrimaryButton className='btn-long' size='large' onClick={handleSubmit}>
                    Continue
                </PrimaryButton>
            </Row>
        </StepContainer>
    );
};

export default FindSfAccountForWcOrder;
