import { configureStore } from '@reduxjs/toolkit';
import appsReducer from './slices/apps';
import connectionsReducer from './slices/connections';
import mappingReducer from './slices/mapping';
import workflowListReducer from './slices/workflow/workflowList';
import latestWorkflowReducer from './slices/workflow/latestWorkflow';
import callbackReducer from './slices/callback';
import subscriptions from './slices/subscriptions';
import profileReducer from './slices/profileSlice';

export const store = configureStore({
        reducer: {
                apps: appsReducer,
                connections: connectionsReducer,
                workflow: workflowListReducer,
                latestWorkflow: latestWorkflowReducer,
                mappings: mappingReducer,
                callback: callbackReducer,
                subscriptions: subscriptions,
                profile: profileReducer
        },
        devTools: true,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
                serializableCheck: false,
        }),
});