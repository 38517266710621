import React, { useContext, useEffect, useState } from 'react';
import { Select, Modal, Spin } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { PrimaryButton, DisabledButton, SecondaryButton } from '../../Commons';
import { StepContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import { fetchWorkflowById } from '../../Http/workflow';
import { useDispatch, useSelector } from 'react-redux';
import { addLatestWorkflow, getLatestWorkflow } from '../../redux/slices/workflow/latestWorkflow';
import { Step } from '../../Http/models/Step.model';
import './style.scss'

const ChooseAccount = ({ authRequestFunction }) => {

	const dispatch = useDispatch();
	const latestWorkflow = useSelector(getLatestWorkflow);
	const SFDC_SANDBOX = 'test';
	const SFDC_PROD = 'login';
	const { appName, appCode, accountAuthorized, next } = useContext(StepContext);
	const [isOpen, setIsOpen] = useState(false);
	const [step, setStep] = useState(new Step());
	const [spinning, setSpinning] = useState(false);


	useEffect(()=>{
		if(latestWorkflow.step1.appCode === appCode){
			setStep(latestWorkflow.step1);
		}else if(latestWorkflow.step2.appCode === appCode){
			setStep(latestWorkflow.step2);
		}
		//console.log({step});
	},[accountAuthorized])

	const adjustLatestWorkflow = async () =>{
		setSpinning(true);
		const workflowRes = await fetchWorkflowById(latestWorkflow.workflowId);
		dispatch(addLatestWorkflow(workflowRes.data));
		setSpinning(false);
		if(latestWorkflow.step1.appCode === appCode){
			setStep(workflowRes.data.step1)
		}else if(latestWorkflow.step2.appCode === appCode){
			setStep(workflowRes.data.step2)
		}
		
		//console.log({step});
	}

	const ButtonAuthorized = () => {
		return appCode === 'SFDC' ? (
			<SecondaryButton
				onClick={() => setIsOpen(true)}
				className='btn-long secondary-button-color'
				size='large'>
				<LockOutlined /> Authorized - Click to change environment
			</SecondaryButton>
		) : (
			<DisabledButton disabled className='btn-long secondary-button-color' size='large'>
				<LockOutlined /> Authorized
			</DisabledButton>
		);
	};
	const ButtonAddAccount = () => {
		let fn =
			appCode === 'SFDC' ? () => setIsOpen(true) : () => authRequestFunction(cb => {
				adjustLatestWorkflow();
			});
		return (
			<PrimaryButton className='btn-long primary-button-color' size='large' onClick={fn}>
				Add Account
			</PrimaryButton>
		);
	};
	const ButtonContinue = () => (
		<PrimaryButton className='btn-long primary-button-color' size='large' onClick={() => next()}>
			Continue
		</PrimaryButton>
	);
	const ChooseEnvironmentModal = () => {
		let environment = SFDC_PROD;
		return (
			<Modal
				visible={isOpen}
				title='Choose Environment'
				onCancel={() => setIsOpen(false)}
				onOk={() => {
					authRequestFunction(environment, cb => adjustLatestWorkflow());
					setIsOpen(false);
				}}>
				<Select
					defaultValue={environment}
					onChange={(v) => (environment = v)}
					className='fill-width'>
					<Select.Option value={SFDC_PROD}>
						Production Environment
					</Select.Option>
					<Select.Option value={SFDC_SANDBOX}>
						Sandbox Environment
					</Select.Option>
					
				</Select>
			</Modal>
		);
	};

	return (
		<StepContainer title='Choose Account'>
			{<Spin style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} spinning={spinning} />}
			{`${appName ? appName : 'unknown'} Account: `}
			<span className='required'>(required)</span>
			{step.accountAuthorized ? <ButtonAuthorized /> : <ButtonAddAccount />}
			{step.accountAuthorized && <ButtonContinue />}
			{appCode === 'SFDC' && <ChooseEnvironmentModal />}
		</StepContainer>
	);
};

export default ChooseAccount;
