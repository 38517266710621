import React, { useContext, useEffect, useState } from 'react';
import { useHistory} from 'react-router-dom';
import { Col, Row, Form, Select, message, Checkbox } from 'antd';
import './style.scss';
import { WorkflowContext, StepContext } from '../../Commons/Context';
import { isObject, keys as keycreate, sample } from 'lodash';
import { PrimaryButton } from '../../Commons';
import { requiredRule, flatten } from '../../Commons/utils';
import { api } from '../../Http';


const WcSalesforceContactMappingForm = (props) => {
  const { sampleData, sampleData2, actionData, mappings, setMappings, form, SObject, workflowId, additionalQuery } = useContext(WorkflowContext);
  const { id, next } = useContext(StepContext);
  const history = useHistory()
  const [keys, setKeys] = useState([]);
  const [keys2, setKeys2] = useState([]);
  const [returnMap, setReturnMap] = useState(false);
  const [message, setMessage] = useState(undefined);


  useEffect(() => {
    if (sampleData) {
      setKeys(keycreate(sampleData));
    }
    if (sampleData2) {
      setKeys2(keycreate(sampleData2));
    }
    // console.log({mappings, sampleData, sampleData2});
    let tmp = {};
    if (sampleData && sampleData2) {

      mappings?.forEach((v) =>
        (v.type === 'TRIG')
          ?
          (
            tmp[v.value] = [`${v.valueStepOrder}.${v.field}`],
            tmp[v.label] = (v.valueStepOrder === 1) ? `${v.valueStepOrder}.${v.value}: ${sampleData[v.value]}` : `${v.valueStepOrder}.${v.value}: ${sampleData2[v.value]}`
          )
          :
          (v.type === 'ACT')
            ?
          (v.field === 'email')
            ?
            (

              tmp['email1'] = [`${v.valueStepOrder}.${v.value}`],
              tmp[v.label] = (v.valueStepOrder === 1) ? `${v.valueStepOrder}.${v.field}: ${sampleData[v.value]}` : `${v.valueStepOrder}.${v.field}: ${sampleData2[v.value]}`
            )
            :
            (

              tmp[v.field] = [`${v.valueStepOrder}.${v.value}`],
              tmp[v.label] = (v.valueStepOrder === 1) ? `${v.valueStepOrder}.${v.field}: ${sampleData[v.value]}` : `${v.valueStepOrder}.${v.field}: ${sampleData2[v.value]}`
            )
            :
            (tmp[v.field] = `${v.value}`)
      );
    } else {
      if (!props.showerror){
        return
      }else{
        message.error('Please proceed step by step from the beginning', 3)
      }
    }
    if (tmp) {
      console.log({temFieldValue:tmp})
      form.setFieldsValue(tmp);
    }
  }, [sampleData, sampleData2]);



  const handleSubmit = () => {
    form
      .validateFields()
      .then((rawFormData) => {
        let ACTION_DATA = {}; //Data to be send as a product of the trigger
        let MAPPINGS = [];

        let flatFormData = flatten({ ...rawFormData}); // add invoice type then flatten

        keycreate(flatFormData)
          .filter((k) => flatFormData[k] && !Array.isArray(flatFormData[k]))
          .map((key) => { //key is xero field like Contact.ContactID or LineItems[0].UnitAmount or LineItemID
            let val = flatFormData[key].split('.');// val is salesforce field like ['1', 'Contact_Xero_Id__c']
            let kname = val.length > 1 ? val[1] : val[0];
            let snum = val.length > 1 ? val[0] : undefined;

            let kval = snum && parseInt(snum) === 1 ? sampleData[kname]
              : snum && parseInt(snum) === 2 ? sampleData2[kname]
                : kname;

            ACTION_DATA = { ...ACTION_DATA, [key]: kval };
            MAPPINGS = snum &&
              (key === 'id' || key ==='email1')
                ?
                [...MAPPINGS, 
                  { type: 'ACT', field: key ==='email1' ? 'email' : key, value: kname, stepId: id, valueStepOrder: snum }

                ]
                : 
                [...MAPPINGS, 
                  { type: 'TRIG', field: kname, value: key, stepId: id, valueStepOrder: snum }
                ];
            return key
          });
          
          // Filter out duplicates based on 'field' and 'stepId'
          MAPPINGS = [...actionData, ...MAPPINGS]
          // .filter((v, i, a) => 
          //   a.findIndex(t => (t.field === v.field && t.value === v.value && t.type === v.type)) === i
          // );
          setMappings(MAPPINGS);
          handleSkip(MAPPINGS)
          //next();
      })
      .catch((e) => console.log(e));
  };

  const RoundedSelect = (props) => {
    const { label, placeholder, name, required, specialOptions, specialOptionsOnly, hint, onSelect } = props;

    return (
      <Col span={22}>
        <div className='label'>
          {label} <span className={required ? 'fr' : 'hidden'}>(required)</span>
        </div>
        <Form.Item name={name} required={required} rules={required ? [requiredRule] : null}>
          <Select className='rounded' mode={specialOptions ? null : 'tags'}
            placeholder={placeholder} allowClear onSelect={onSelect}>

            {specialOptions && specialOptions.map((v) => <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>)}

            {!specialOptionsOnly &&
              keys.map(
                (v, k) =>
                  !isObject(sampleData[v]) && (
                    <Select.Option value={`1.${v}`} key={`1.${k}`}>
                      <div className='opt-div'>
                        <span className='key'>{`1.${v}:  `}</span>
                        {`${sampleData[v]}`}
                      </div>
                    </Select.Option>
                  )
              )}

            {!specialOptionsOnly &&
              keys2.map(
                (v, k) =>
                  !isObject(sampleData2[v]) && (
                    <Select.Option value={`2.${v}`} key={`2.${k}`}>
                      <div className='opt-div'>
                        <span className='key'>{`2.${v}:  `}</span>
                        {`${sampleData2[v]}`}
                      </div>
                    </Select.Option>
                  )
              )}
          </Select>
        </Form.Item>
        {hint && <div className='hint'>{hint}</div>}
      </Col>
    );
  };

  const onChange = (event) => {
    console.log({ event, form: form.getFieldsValue() });
  };
  const handleSkip = (maps) => {
    api.post('/api/v1/workflows/mappings', { mappings: maps, sObject: SObject, workflowId: workflowId, additionalQuery: additionalQuery })
        .then(resp => {
            console.log({ resp })
            setMessage(resp.data.message)
        })
        .catch(e => console.log(e));

    setTimeout(() => {
        history.push('/system/workflows');
    }, 1000)
};

  return (
    <Row className='padded xinvform'>
      <div className='title'>Creating Contact Mapping</div>
      <Col span={24}>

        <Form layout='vertical' form={form} scrollToFirstError={true} onFinish={handleSubmit} onValuesChange={onChange}>
          <fieldset hidden={returnMap} className='fieldset' style={{ border: '1px solid #e2e2e2' }}>
            <legend>Account</legend>
            <Row hidden={returnMap} justify='center'>
              <RoundedSelect label='Account Name' name='billing.company' required={true} placeholder='Type or insert..' />
              <RoundedSelect label='Phone' name='billing.phone' placeholder='Type or insert..' />
              <RoundedSelect label='BillingCity' name='billing.city' placeholder='Type or insert..' />
              <RoundedSelect label='BillingCountry' name='billing.country' placeholder='Type or insert..' />
              <RoundedSelect label='BillingPostalCode' name='billing.postcode' placeholder='Type or insert..' />
              <RoundedSelect label='BillingState' name='billing.state' placeholder='Type or insert..' />
              <RoundedSelect label='ShippingCity' name='shipping.city' placeholder='Type or insert..' />
              <RoundedSelect label='ShippingCountry' name='shipping.country' placeholder='Type or insert..' />
              <RoundedSelect label='ShippingPostalCode' name='shipping.postcode' placeholder='Type or insert..' />
              <RoundedSelect label='ShippingState' name='shipping.state' placeholder='Type or insert..' />
            </Row>
          </fieldset>

          <fieldset hidden={returnMap} className='fieldset' style={{ border: '1px solid #e2e2e2' }}>
            <legend>Contact</legend>
            <Row justify='center'>
              <RoundedSelect label='First Name' name='first_name' required={true} placeholder='Type or insert..' />
              <RoundedSelect label='Last Name' name='last_name' required={true} placeholder='Type or insert..' />
              <RoundedSelect label='Email' name='email' required={true} placeholder='Type or insert..' />
            </Row>
          </fieldset>

          <PrimaryButton hidden={returnMap} className='btn-long' size='large' htmlType='button' onClick={() => setReturnMap(true)}>
            Continue
          </PrimaryButton>

          <Row hidden={!returnMap} justify='center'>
            <RoundedSelect label='CustomerId(id)' name='id' required={true} placeholder='Type or insert External Id' />
            <RoundedSelect label='CustomerEmail(email)' name='email1' required={true} placeholder='Type or insert email' />
            <PrimaryButton hidden={!returnMap} className='btn-long' size='large' htmlType='submit'>
              Continue
            </PrimaryButton>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default WcSalesforceContactMappingForm;
