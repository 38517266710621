export const local = {
    label: "local",
    protocol: "http",
    server_url : "localhost",
    port: "8001",
    QUICKBOOKS_CLIENT_ID: "ABsXYYDo9C6OQUQEV7VY8EVrEZj5fmSz2J2chPuA3TkjnZxEbc",//test
    // XERO_CLIENT_ID: "718762B2BF3A4FFCA0D539B7860D4F8F",//real
    XERO_CLIENT_ID: "B80B6894D1C543D6AF6332CC22EBF714",//test
    XERO_SIGN_CALLBACK: "http://localhost:8081/xero-signin/callback?origin=nettverk",
    XERO_SIGN_CALLBACK_WITH_ORIGIN: "http://localhost:8081/xero-signin/callback?origin=xero_store",
    sfdc_callback: "http://localhost:8081/salesforce/callback",
    sfc_client_id: "3MVG9n_HvETGhr3BjMXMnXnsGN7u5T1MPhdo4nKjaYeCO6vbpZm1qXKPiqt0cvFpu7CmB5NEtzm_HHq8cmHSF",
    sfdc_sandbox_domain: "test.salesforce.com",
    QUICKBOOKS_SIGN_CALLBACK: "http://localhost:8081/quickbooks-signin/callback",
    sfdc_prod_domain: "login.salesforce.com",
    trial_starter: "price_1LrDrlAYds1iVm9Y8idcAwqm",
    professional: "price_1LsPHlAYds1iVm9Y2U3b8YJ2",
    stripe_public_key: "pk_test_51LjiO7AYds1iVm9YfpNxtJV2IfrrA6H8rmcaDaCLeceWcmcRcJATSWzXGMl0wzNBlmWWDKhaHqyZ4CUtZ2m88XBk00FeETylM4"
}
export const dev = {
    label: "dev",
    protocol: "https",
    server_url: "nvdevapi.nettverk.io",
    port : null,
    XERO_CLIENT_ID: "718762B2BF3A4FFCA0D539B7860D4F8F",
    QUICKBOOKS_CLIENT_ID: "ABsXYYDo9C6OQUQEV7VY8EVrEZj5fmSz2J2chPuA3TkjnZxEbc",
    XERO_SIGN_CALLBACK: "https://nettverk-dev.uc.r.appspot.com/xero-signin/callback?origin=nettverk",
    XERO_SIGN_CALLBACK_WITH_ORIGIN: "https://nettverk-dev.uc.r.appspot.com/xero-signin/callback?origin=xero_store",
    sfdc_callback: "https://nettverk-dev.uc.r.appspot.com/salesforce/callback",
    sfc_client_id: "3MVG9n_HvETGhr3BjMXMnXnsGN7u5T1MPhdo4nKjaYeCO6vbpZm1qXKPiqt0cvFpu7CmB5NEtzm_HHq8cmHSF",
    sfdc_sandbox_domain: "test.salesforce.com",
    QUICKBOOKS_SIGN_CALLBACK: "https://nettverk-dev.uc.r.appspot.com/quickbooks-signin/callback",
    sfdc_prod_domain: "login.salesforce.com",
    trial_starter: "price_1LojI8AYds1iVm9YQSs2jueu",
    professional: "price_1LrDr9AYds1iVm9YHTkJrb6v",
    stripe_public_key: "pk_live_51LjiO7AYds1iVm9YledO16NqeJy8mBcj3Q3p4fo29mS0eRLMHQnO0TL868Glq4XWkV21kyKInVxhB56REbNo9rAN006zJ1KKqd"
}
export const prod = {
    label: "prod",
    protocol: "https",
    server_url: "nvapi.nettverk.io",
    port : null,
    XERO_CLIENT_ID: "718762B2BF3A4FFCA0D539B7860D4F8F",
    QUICKBOOKS_CLIENT_ID: "ABsXYYDo9C6OQUQEV7VY8EVrEZj5fmSz2J2chPuA3TkjnZxEbc",
    XERO_SIGN_CALLBACK: "https://app.nettverk.io/xero-signin/callback?origin=nettverk",
    XERO_SIGN_CALLBACK_WITH_ORIGIN: "https://app.nettverk.io/xero-signin/callback?origin=xero_store",
    sfdc_callback: "https://app.nettverk.io/salesforce/callback",
    sfc_client_id: "3MVG9n_HvETGhr3BjMXMnXnsGN7u5T1MPhdo4nKjaYeCO6vbpZm1qXKPiqt0cvFpu7CmB5NEtzm_HHq8cmHSF",
    sfdc_sandbox_domain: "test.salesforce.com",
    QUICKBOOKS_SIGN_CALLBACK: "https://app.nettverk.io/quickbooks-signin/callback",
    sfdc_prod_domain: "login.salesforce.com",
    trial_starter: "price_1LojI8AYds1iVm9YQSs2jueu",
    professional: "price_1LrDr9AYds1iVm9YHTkJrb6v",
    stripe_public_key: "pk_live_51LjiO7AYds1iVm9YledO16NqeJy8mBcj3Q3p4fo29mS0eRLMHQnO0TL868Glq4XWkV21kyKInVxhB56REbNo9rAN006zJ1KKqd"
}

export const ENVIRONMENT = prod;
