import React, { useEffect, useState, useContext } from 'react';
import { Row, Select } from 'antd';
import { PrimaryButton } from '../../Commons';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import { isSuccess } from '../../Http';

const ChooseSalesforceAccountType = () => {
	const { mappings, setActionData} = useContext(WorkflowContext);
	const { appName, next, id } = useContext(StepContext);
	const [options, setOptions] = useState([
		{id:1, type:"Account-Contact"},
		{id:2, type:"Personal Account"}
	]);
	const [accountType, setAccountType] = useState();
	const [accounTypeId, setAccountTypeId] = useState();
	const [preSelectedAccountType, setPreSelectedAccountType] = useState({});
	const [loading, setLoading] = useState(true);

	//2. When retrieved, fetch the old selected sobject if any
	useEffect(() => {
		if (options.length > 0) getAccountType();
	}, [options]);

	const getAccountType = async () => {
		mappings?.forEach((v) =>{
			if(v.type === 'CON' && v.field === 'AccountType'){
				options?.forEach((m)=>{
					if(m.id === v.value){
						setAccountTypeId(m.id);
						setPreSelectedAccountType({ type: v.type, field: v.field, value: v.value,  stepId: id, valueStepOrder: v.valueStepOrder })
					}
				})
			}
		})
	};

	const handleSelect = (pId) => {
		setAccountTypeId(pId);
		options?.forEach((m)=>{
			if(m.id === pId){
				setAccountType(m.type);
			}
		})
	};
	const handleMapping = ()=>{
		let MAPPINGS = [];
		MAPPINGS = accounTypeId 
		? [...MAPPINGS, { type: 'CON', field: "AccountTypeId", value: accounTypeId,  stepId: id, valueStepOrder: 1 }]
		: [...MAPPINGS, preSelectedAccountType];
		console.log({MAPPINGS})
		setActionData(MAPPINGS);
		next();
	}

	return (
		<StepContainer title='Choose Account'>
			<div>
				{`${appName} Account Type: `} <span className='required'>(required)</span>
			</div>

			<Row justify='center'>
				<Select
					className='fill-width rounded'
					//loading={loading}
					onSelect={handleSelect}
					placeholder='Select'
					showSearch
					value={accountType}
					optionFilterProp='children'>
					{options.map((v, k) => (
						<Select.Option value={v.id} key={k}>
							{v.type}
						</Select.Option>
					))}
				</Select>
			</Row>

			<Row justify='center'>
				<PrimaryButton
					className='btn-long'
					size='large'
					onClick={() => handleMapping()}>
					Continue
				</PrimaryButton>
			</Row>
		</StepContainer>
	);
};

export default ChooseSalesforceAccountType;
