import { AntDesignOutlined, DisconnectOutlined, MailFilled, UserOutlined, DownloadOutlined, CreditCardFilled } from "@ant-design/icons"
import { Button, Card, Modal, message } from "antd"
import { useHistory } from "react-router-dom"
import { Table } from "antd"
import { cancelSubscription, getActiveSubscription } from "../../Http/pricing"
import { useEffect, useState } from "react"
import moment from 'moment'
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Invoice } from "../../Http/models/invoice.model"
import { fetchInvoices } from "../../Http/invoices/invoice"
import { StripePaymentCard } from "../../Components/StripePaymentCard"

const BillingUsage = () => {
    const history = useHistory()
    const plandetails = JSON.parse(localStorage.getItem("plan"))
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initUpdatePayment, setInitUpdatePayment] = useState(false);
    const [invoices, setInvoices] = useState(Invoice.List());
    const email = localStorage.getItem("email")
    const stripe = useStripe();
    const elements = useElements();
    const subs = JSON.parse(localStorage.getItem('subscription'))

    const columns = [
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoiceNumber',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            
        },
        {
            title: 'Paid Date',
            dataIndex: 'paidDate',
        },
        {
            title: 'Paid Amount',
            dataIndex: 'amountPaid',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Download Invoice',
            dataIndex: 'invoicePdf'
        }
    ];


    useEffect(()=>{
        getInvoices();
    },[]);

    const getInvoices = async () =>{
        const invoicesRes = await fetchInvoices();
        invoicesRes.data.map(i => {

            i.duration = (i.periodStart === null && i.periodEnd === null) ? '...' : moment.unix(i.periodStart).utc().format("ll") + " - " + moment.unix(i.periodEnd).utc().format("ll");
            i.paidDate = i.paidDate === null ? '...' : moment.unix(i.paidDate).utc().format("ll");
            i.status = (<span style={{textTransform: 'capitalize'}}>{i.status}</span>)
            i.hostedInvoiceUrl = (<Button   disabled={i.status !== 'paid'} href={i.hostedInvoiceUrl} type={i.status !== 'paid' ? 'default' : 'primary'} shape="round" icon={<CreditCardFilled />} >
                                    Pay now
                                </Button>);
            i.invoicePdf = (<Button href={i.invoicePdf} type="primary" shape="round" icon={<DownloadOutlined />} >
                                Download
                            </Button>);
        });
        //console.log({invoicesRes:invoicesRes.data})
        setInvoices(invoicesRes.data);
    }

    const formateDate = (date) => {
        const newDate = new Date(date);
        return newDate.getDate() + "/" + newDate.getMonth() + "/" + newDate.getFullYear()
    }


    const handleCancelSub = async() => {
        setLoading(true)
        const getSub = JSON.parse(localStorage.getItem("subscription"))
        const data = {
            paymentgatewayref: getSub.paymentgatewayref
        }
        const response = await cancelSubscription(data)
        
        if(response && response.code === 0){
            setLoading(false)
            setIsModalOpen(false)
            message.success("Subscription Cancelled Succcessfully")
            const activesub = await getActiveSubscription()
            localStorage.setItem("subscription", JSON.stringify(activesub.data.subscription))
            localStorage.setItem("plan", JSON.stringify(activesub.data.plan))
        }else{
            setLoading(false)
            setIsModalOpen(false)
            message.error("Subscription Cancelled Unsucccessfully")
        }
    }

    const handleSubmitPaymentInfo = () => {
        setLoading(true);
        setInitUpdatePayment(true);
    }

    const paymentUpdated = (success, result) => {
        setLoading(false);
        setInitUpdatePayment(false);
        setIsInfoModalOpen(false);
    }


    const handleCancel = () => {
        setIsModalOpen(false);
        setIsInfoModalOpen(false);
    };

    const onChange = (pagination, filters, sorter, extra) => {
        //console.log('params', pagination, filters, sorter, extra);
    };


    return (
        <div 
        className="subscription-wrapper"
        >
            <Card bordered>
                <div className="card-inner">
                    <div className="first-div">
                        <AntDesignOutlined className="anticon" />
                        <div className="accountinfo">
                            <div className="normaltext">Peronsonal Account</div>
                            {plandetails ? <div className="planname">{plandetails?.name}: ${plandetails?.currentprice} monthly</div> : <div className="planname">No Plan Activated</div>}
                            
                        </div>
                    </div>
                    {plandetails.subId !== 4 ? 
                    <div className="second-div">
                        <Button onClick={() => history.push('/system/pricing-plan')} type="primary" size="large" ghost >{subs.active !== 0 ? "Change Plan" : "Reactive Plan"}</Button>
                    </div> : null}
                </div>
            </Card>
            {subs.active !== 0 && plandetails.subId !== 4 &&
            <Card bordered className="cancel-sub">
                <div className="card-inner">
                    <div className="first-div">
                        <DisconnectOutlined className="anticon" />
                        <div className="accountinfo">
                            <div className="normaltext">Cancel Subscription</div>
                            <div className="planname">For Cancelling Your Subscription</div>
                        </div>
                    </div>
                    <div className="second-div">
                        <Button onClick={() => setIsModalOpen(true)} type="primary" size="large" ghost >Cancel Subscription</Button>
                    </div>
                </div>
            </Card>}
            <h1 className="second-title">Payment Information</h1>
            <Card bordered>
                <div className="card-inner">
                    <div className="first-div">
                        <CreditCardFilled style={{color: "#3337"}} className="anticon" />
                        {/* <div className="accountinfo">
                            <div className="normaltext">Visa Ending In 2025</div>
                            <div className="planname">Expires 10/23</div>
                        </div> */}
                    </div>
                    <div className="second-div">
                        <Button onClick={() => setIsInfoModalOpen(true)} type="primary" size="large" ghost >Edit Payment Information</Button>
                    </div>
                </div>
            </Card>
            <h1 className="second-title">Invoice Information</h1>
            <Table columns={columns} dataSource={invoices} onChange={onChange} />



            {/* for cancel modal */}
            <Modal title="Subscription Cancel" visible={isModalOpen} okText="Yes" onOk={handleCancelSub} onCancel={handleCancel} confirmLoading={loading}>
                <h4>
                    Do you want to cancel Subscription?
                </h4>
            </Modal>

            {/* for changing payment information */}
            {/* Payment Modal */}
            <Modal title="Payment Information" visible={isInfoModalOpen} okText="Update" onOk={handleSubmitPaymentInfo} onCancel={handleCancel} confirmLoading={loading}>

                {/* <Input placeholder='Enter Email Addresss' size='large' prefix={<UserOutlined />} disabled defaultValue={email} />
                <div className="card_details">
                    <CardInput />
                </div> */}
                <StripePaymentCard
                    updatePaymentMethod={true}
                    initSumbit={initUpdatePayment}
                    modalView={true}
                    onComplete={paymentUpdated}
                />
            </Modal>

                <Button className='float-btn' href="mailto: support@nettverk.io" icon={<MailFilled />} shape="round" type="primary">Get Help</Button>
        </div>
    );
}


export default BillingUsage