import React from 'react';
import { BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import Login from './Layout/Login';
import Signup from './Layout/Login/Signup';
import MainContainer from './Layout/MainContainer';
import CallBackPage from './Layout/CallBackPage';
import ChangePassPage from './Layout/ChangePassPage/ChangePass';
import ForgotPass from './Layout/Login/ForgotPass';
import { InitialPayment } from './Layout/InitialPayment';
import XeroStoreCallBack from './Layout/XeroStoreCallBackPage/XeroStoreCallBack';
import VerifyCode from './Layout/Login/VerifyCode';
import VerifySecret from './Layout/Login/VerifySecret';
import NotFound from './Components/NotFound';


const Routes = () => {

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path={['/', '/login']} component={Login} />
				<Route exact path={['/register/user']} component={Signup} />
				<Route exact path={['/payment']} component={InitialPayment} />
				<Route exact path={'/tfa-verifycode'} component={VerifyCode} />
				<Route exact path={'/tfa-verifysecret'} component={VerifySecret} />
				<Route
					path={['/system/dashboard', '/system']}
					component={MainContainer}
				/>
				<Route
					path={['/salesforce/callback', '/xero/callback', '/confirm','/xero-signin/callback','/quickbooks/callback','/quickbooks-signin/callback']}
					component={CallBackPage}
				/>
				<Route
				path={'/xerosignup'}
				component={XeroStoreCallBack}
				/>
				<Route path={['/forgot-password']} component={ForgotPass} />
				<Route path={['/change-password']} component={ChangePassPage} />
				<Route path="*" component={NotFound} />
			</Switch>
		</BrowserRouter>
	);
}


export default Routes;
