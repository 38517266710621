// import React, { useEffect, useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import jwt_decode from "jwt-decode";
// import { Icon } from '@ant-design/compatible';
import { Row, Menu, Col, Layout, Progress, Tooltip } from 'antd';
import logo from '../images/logo-black-01.svg';
import styled from 'styled-components';
import './style.css';
import { FileTextOutlined } from '@ant-design/icons';
import useTransaction from '../hooks/useTransaction';
import { LogoIcon } from "../images/Icon"
const { Sider } = Layout;

const NavItems = (siderHidden, routes) => {
	if (!routes || routes.length === 0) return '';
	return (
		<Menu className='menu-item-style' mode='inline' style={{ backgroundColor: 'inherit' }} theme='light'>
			{routes.flatMap((val, key) => {
				if (val.items && val.items.length > 0) {
					return (
						<Menu.SubMenu
							key={val.pk}
							title={
								<span>
									{val.icon}
									{/* <Icon component={val.icon} /> {siderHidden ? '' : val.name} */}
								</span>
							}>
							{val.children.map((v, k) => (
								<Menu.Item className='paymate-sider-item' key={val.pk + k}>
									<Link to={v.path}>{v.name}</Link>
								</Menu.Item>
							))}
						</Menu.SubMenu>
					);
				} else {
					return val.hidden ? null : (
						<Menu.Item className='paymate-sider-item' key={key}>
							<Link to={val.path}>
								{/* <Icon component={val.icon} /> */}
								{val.icon}
								<span>{val.name}</span>
							</Link>
						</Menu.Item>
					);
				}
			})}
			{/* <Menu.Item className='paymate-sider-item' key={"documentation"}>
				<Link to={{pathname: "https://nettverk.io/docs/nettverk/"}} target="_blank" >
					<FileTextOutlined />
					<span>Documentation</span>
				</Link>
			</Menu.Item> */}
		</Menu>
	);
};

const DashBoardSidebar = (props) => {
	const[transactions, loadingTransaction] =useTransaction();

	const { siderHidden, setSiderHidden, routes } = props;
	const { StyledSider, Logo, StyledRow, Divider } = StyledComponents;
	
	const plan = JSON.parse(localStorage.getItem("plan"));
	const token = localStorage.getItem("token");
	const tokenDecoded = token && jwt_decode(token);
	const consumedTransactions = transactions?.transactionsByWorkflow.reduce((total, currentValue) => total + currentValue['transactions'], 0);
	const totalTransactions = plan?.noOftransaction;
	const percentageOfConsumption = ((consumedTransactions*100)/totalTransactions)?.toFixed(2);


	return (
		<StyledSider
			hidden={window.screen.width < 420 && siderHidden}
			breakpoint='lg'
			width='200'
			onBreakpoint={(broken) => setSiderHidden(broken)}
			collapsible
			collapsed={siderHidden}
			trigger={null}>
			{/* <StyledRow siderhidden={+siderHidden} type='flex' align='stretch'>
				<Col xs={24}>
					<div style={{ marginTop: '2.5rem',textAlign:'center' }}>
						<Logo src={logo} siderhidden={+siderHidden} />
					</div>
				</Col>
			</StyledRow> */}
			<StyledRow siderhidden={+siderHidden} type='flex' align='stretch'>
				<Col xs={24}>
					<div style={{ marginTop: '2.5rem' }}>

					</div>
				</Col>
			</StyledRow>
			{NavItems(siderHidden, routes)}

			<StyledRow siderhidden={+siderHidden} type='flex' align='stretch' style={{ position:"absolute", bottom:"10vh", width:"100%"}}>
				<Col xs={24}>
					{
						tokenDecoded.userType !== "ADM" &&
						<div style={{textAlign:"center"}}>
							<div style={{}}>
								<Tooltip
								title={<div className='tooltip-color-custom'>Transaction Usage</div>}
								color='#fff'  
								placement="top"
								>
									{/* <Progress 
										className='ant-progress-inner-custom' 
										strokeLinecap="butt"
										percent={percentageOfConsumption}
										strokeWidth = {25}
										format={(percent) => <div className='progress-percentage'>{`${percent} %`}</div>}
									/> */}
									<Progress
										style={{opacity: "0.802", 
										padding:'25px 30px 25px 30px', 
										border:'1px solid rgb(215, 213, 210)', 
										borderRadius:'2px',
										// boxShadow:'rgba(0, 0, 0, 0.2) 0px 7.5px 15px 0.1px'
									}}
										type="circle"
										strokeColor={{
											'0%': '#5524b9',
											'100%': '#c333db',
										}}
										width={100}
										strokeWidth={10}
										percent={percentageOfConsumption}
										format={(percent) => <div className='progress-percentage'>{`${percent} %`}</div>}
									/>
								</Tooltip>
							</div>
						</div>
					}
				</Col>
			</StyledRow>
			
		</StyledSider>
	);
};

DashBoardSidebar.propTypes = {
	siderHidden: PropTypes.bool,
	setSiderHidden: PropTypes.func,
};
// background-color: #073763 !important;
const StyledComponents = {
	StyledSider: styled(Sider)`
		background-color: #FFFDF9 !important;
		color:rgba(0, 0, 0, 0.85) !important;
		border-right: 1px solid rgb(215, 213, 210);
		height: 100vh;
		overflow-y: auto;
		overflow-x: hidden;
	`,

	StyledRow: styled(Row)`
		display: ${(props) => (props.siderhidden ? 'none !important' : '')};
		margin: 'auto';
	`,
	Logo: styled.img`
		width: 80% !important;
		height: 10% !important
		display: block;
	`,
// 	Logo: styled.img`
// 	width: 100% !important;
// 	height: 10% !important
// 	margin: ${(props) =>
// 		props.siderhidden ? '2rem auto 2rem auto' : '2rem auto auto auto'};
// 	display: block;
// `,

	Divider: styled.hr`
		margin-top:0rem;
		margin-left: 1.2rem;
		margin-right: 1.2rem;
		margin-bottom: 1.2rem;
	`,
	Avatar: styled.div`
		height: 6rem;
		width: 6rem;
		margin: auto;
		background-color: white;
		border-radius: 10px;
	`,
	ProfileName: styled.span`
		color: white;
		text-align: center;
		font-size: 1.1rem;
		display: block;
	`,
};

export default DashBoardSidebar;
