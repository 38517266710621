import {
	SFDC_NEW_RECORD,
	SFDC_UPDATE_RECORD,
	SFDC_FIND_RECORD,
	XERO_UPDATE_INVOICE_RECORD,
	XERO_CREATE_SALES_INVOICE,
	XERO_CREATE_OR_UPDATE_CONTACT,
	XERO_CREATE_OR_UPDATE_ITEMS,
	XERO_CREATE_CREDIT_NOTE,

	XERO_TRIGGER_INVOICE_RECORD,
	XERO_NEW_MODIFY_ITEM,
	XERO_NEW_MODIFY_INVOICE,
	SALES_FORCE_CREATE_NEW_SALES_INVOICE,
	SALES_FORCE_CREATE_NEW_PRODUCT_RECORD,
	XERO_NEW_PAYMENT_RECORD,
	SFDC_CREATE_OR_UPDATE_PAYMENT,
	XERO_CREATE_OR_UPDATE_PURCHASE_ORDER,
	NEW_XERO_CREDIT_NOTE,
	SFDC_CREATE_UPDATE_CREDIT_NOTE,
	XERO_CREATE_QUOTE,
	XERO_UPDATE_QUOTE_RECORD,
	SFDC_UPDATE_QUOTE,
	WC_NEW_PRODUCT,
	SFDC_SYNC_WC_PRODUCT,
	SFDC_TRIGGER_XERO_PDF,
	XERO_CREATE_INVOICE_PDF,
	WC_NEW_CUSTOMER,
	SFDC_SYNC_ACCOUNT,
	WC_NEW_ORDER,
	SFDC_SYNC_OPPORTUNITY

} from '../../steps';

const AppMap = {
	NREC: SFDC_NEW_RECORD,
	UREC: SFDC_UPDATE_RECORD,
	FREC: SFDC_FIND_RECORD,
	CSIN: XERO_CREATE_SALES_INVOICE,
	CCON: XERO_CREATE_OR_UPDATE_CONTACT,
	CITM: XERO_CREATE_OR_UPDATE_ITEMS,
	CCRNT: XERO_CREATE_CREDIT_NOTE,
	SFCSIN: SALES_FORCE_CREATE_NEW_SALES_INVOICE,
	NSPE: SALES_FORCE_CREATE_NEW_PRODUCT_RECORD,
	SFIPDF: SFDC_TRIGGER_XERO_PDF,
	XIPDF: XERO_CREATE_INVOICE_PDF,
	XIUP: XERO_UPDATE_INVOICE_RECORD,
	XNREC: XERO_TRIGGER_INVOICE_RECORD,
	FITM: XERO_NEW_MODIFY_ITEM,
	XNINV: XERO_NEW_MODIFY_INVOICE,
	XNPR: XERO_NEW_PAYMENT_RECORD,
	SFNPR: SFDC_CREATE_OR_UPDATE_PAYMENT,
	XNPO: XERO_CREATE_OR_UPDATE_PURCHASE_ORDER,
	XNCRN: NEW_XERO_CREDIT_NOTE,
	SFCRN: SFDC_CREATE_UPDATE_CREDIT_NOTE,
	CXQT: XERO_CREATE_QUOTE,
	XQUP: XERO_UPDATE_QUOTE_RECORD,
	USFQ: SFDC_UPDATE_QUOTE,

	NWCPE: WC_NEW_PRODUCT,
	CNSFP: SFDC_SYNC_WC_PRODUCT,
	NWCCE: WC_NEW_CUSTOMER,
	CNSFC: SFDC_SYNC_ACCOUNT,
	NWCOR: WC_NEW_ORDER,
	CSFOP: SFDC_SYNC_OPPORTUNITY

};


export { AppMap};
