import React, { useEffect, useContext, useState } from 'react';
import { Row, Col, Select, message, Button } from 'antd';
import { SecondaryButton } from '../../Commons';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import { delegateSaleForce } from '../../Http/salesforce/delegate';

const FindData = ({showNext}) => {
	const { SObject, sampleData, setSampleData } = useContext(WorkflowContext);
	const [recentItems, setRecentItems] = useState(undefined);
	const { next } = useContext(StepContext);
	const [visible, setVisible] = useState(false);


	//console.log({ contextobject: useContext(WorkflowContext) })

	const params = (uri) => ({ uri: uri });

	useEffect(() => {
		getAllDelegateSalesForce();
	}, []);

	const getAllDelegateSalesForce = async () => {
		// TODO: null validation
		const delegateSalesForceRes =  await delegateSaleForce( params(SObject.urls.sobject) );
		const data = delegateSalesForceRes.data.recentItems;
		setRecentItems(data);
		if(! data[0]) return message.error('no data found');
		const delegateSalesForceAttrRes = await delegateSaleForce( params(data[0].attributes.url) );
		if(delegateSalesForceAttrRes) setSampleData(delegateSalesForceAttrRes.data);
	};

	const handleSelect = async (k) => {
		const delegateSalesForceRes =  await delegateSaleForce( params(recentItems[k].attributes.url) );
		if(delegateSalesForceRes) setSampleData(delegateSalesForceRes.data);
	};

	return (
		<StepContainer title='Find Data'>
			<Row justify='center' className={visible ? null : 'hidden'}>
				{recentItems && (
					<Col span={22}>
						<div>{`( ${recentItems.length} Data Found )`}</div>
						<Select
							className='fill-width'
							defaultValue={0}
							onSelect={handleSelect}>
							{recentItems.map((v, k) => (
								<Select.Option value={k} key={k}>
									{v.Name}
								</Select.Option>
							))}
						</Select>
					</Col>
				)}
				<Col span={22} className='mt-2 json-view'>
					<pre>{JSON.stringify(sampleData, null, 2)}</pre>
				</Col>
			</Row>

			<Row justify='center' style={{marginTop: '10px'}} className={visible ? null : 'hidden'}>
				<SecondaryButton hidden={!showNext} size='large' onClick={()=>next()}>
					Continue
				</SecondaryButton>
			</Row>

			<Row justify='center' className={visible ? 'hidden' : null}>
				<SecondaryButton size='large' onClick={() => setVisible(true)}>
					Test Trigger
				</SecondaryButton>
			</Row>
		</StepContainer>
	);
};

export default FindData;
