import { Button, Row} from "antd"
import { useState } from "react"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import StepContainer from "../../Components/StepContainer";
import { getLatestWorkflow} from "../../redux/slices/workflow/latestWorkflow";
import { createWoocommerceWebHook } from "../../Http/webhook/wcWebHook";


export const AddWcWebhook = () => {
        const [buttonLoading, setButtonLoading] = useState(false);
        const latestWorkflow = useSelector(getLatestWorkflow);

        const enableWebhookHandler = async () =>{
                setButtonLoading(true);
                const workflowId = latestWorkflow.workflowId;
                const webhookPostRes = await createWoocommerceWebHook(workflowId);
                if(webhookPostRes.code === 0){
                        toast.success("Webhooks Enabled Successfully..!");  
                }
                setButtonLoading(false);
        }
        

        return (
                <StepContainer title='Connect to Woocommerce outbound messages'>
                        <ToastContainer />
                        <Row justify='center'>
                                <Button loading={buttonLoading} type="primary" size='large' onClick={enableWebhookHandler}>
                                        Enable Webhook
                                </Button>
                        </Row>
                </StepContainer>
        )
}
