import React, { useState, useEffect } from 'react';
import DashboardSidebar from '../../Components/SideBar';
import { Switch, Route } from 'react-router-dom';
import { Row, Col, Layout, Spin } from 'antd';
import { userRoutes } from '../../Routes/user';
import { useCookies } from 'react-cookie';
import '../../Commons/style/style.scss';
import { AppContext } from '../../Commons/Context';
import jwt_decode from 'jwt-decode';
import './style.scss';
import { adminRoutes } from '../AdminConsole/adminRoutes';
import CustomHeader from '../../Components/CustomeHeader';
import NotFound from '../../Components/NotFound';
const { Content } = Layout;

const NavigationRoutes = (routes) => {
    return (
        <Switch>
            {routes.map((route, key) =>
                route.items && route.items.length > 0
                    ? route.items.map((subRoute, index) => (
                          <Route
                              exact
                              path={subRoute.path}
                              component={subRoute.component}
                              key={`${route.pk}-${index}`}
                          />
                      ))
                    : <Route exact path={route.path} component={route.component} key={key} />
            )}
            {/* Catch-all route */}
            <Route path="*" component={NotFound} />
        </Switch>
    );
};

const MainContainer = (props) => {
	const [siderHidden, setSiderHidden] = useState(false);
	const [cookies] = useCookies(['Authorization']);
	const [currentUser, setCurrentUser] = useState();
	const [userType, setUserType] = useState(null);
	const token = localStorage.getItem('token');

	useEffect(() => {
		const validateUser = () => {
			const localToken = localStorage.getItem('token');
			if (!cookies.Authorization || !localToken) {
				console.warn("Token not found. Redirecting to login...");
				props.history.replace('/login');
				return;
			}
	
			try {
				const subs = JSON.parse(localStorage.getItem("subscription"));
				if (!subs || !subs.paymentgatewayref) {
					console.warn("No subscription found. Redirecting to payment...");
					props.history.replace('/payment');
					return;
				}
	
				const decodedToken = jwt_decode(cookies.Authorization);
                setCurrentUser(decodedToken.firstName);
                setUserType(decodedToken.userType); // Extract and store userType
			} catch (error) {
				console.error("Error decoding token:", error);
				props.history.replace('/login');
			}
		};
	
		validateUser();
	}, [cookies, props.history]);

	if (!token) {
        return <Spin/>;
    }
	const routeFilter = (routes)=>{
		if(userType === 'ADM'){
		   return [...adminRoutes]
		}
		return routes;
	}
	return (
		<AppContext.Provider value={{ currentUser }}>
			<Layout >
				<CustomHeader
					siderHidden={siderHidden}
					setSiderHidden={(val) => setSiderHidden(val)}
				/>
				<Layout >
					<DashboardSidebar
						// className="common-style"
						siderHidden={siderHidden}
						setSiderHidden={(val) => setSiderHidden(val)}
						routes={routeFilter(userRoutes)}
					/>
					<Layout
						style={{
							paddingLeft:"20px",
							paddingRight:"20px",
							paddingTop:"20px",
						}}
						className='main-layout-style'
					>
						<Content style={{}}>
							<Row
								style={{
									paddingTop:"0px",
									// paddingBottom:"70px",
								}}
								justify='start'
							 >
								<Col xs={24}>
									<Row>{NavigationRoutes(routeFilter(userRoutes))}</Row>
								</Col>
							</Row>
						</Content>

					</Layout>
				</Layout>
            </Layout>
		</AppContext.Provider>
	);

};

export default MainContainer;
